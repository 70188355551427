import React, { useState, useEffect } from "react";
import CredHeader from "./../components/Headers/CredHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ToastContainer, toast } from "react-toastify";
import Auth from "./../helpers/Auth";
import "react-toastify/dist/ReactToastify.css";
import companies from "helpers/Companies";
import Select from "react-select";

import { parsePhoneNumber, parse } from 'libphonenumber-js'

import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  FormGroup,
  Dropdown,
  Container,
  Button,
  Row,
  Input,
  CardBody,
  Col,
  Label,
  Form,
  Modal,
} from "reactstrap";
const fetch = require("node-fetch");
const moment = require("moment");

const vaccineDoses = (vaccine) => {
  switch (vaccine) {
    case "Pfizer-BioNTech":
      return ["1/2", "2/2", "3/3"];
    case "Spikevax (previously Moderna)":
      return ["1/2", "2/2", "3/3"];
    case "Vaxzevria (previously AstraZeneca)":
      return ["1/2", "2/2"];
    case "Janssen (Johnson & Johnson)":
      return ["1/1"];
    case "BIBP/Sinopharm":
      return ["1/2", "2/2", "3/3"];
    case "COVISHIELD™":
      return ["1/2", "2/2"];
    case "CoronaVac":
      return ["1/2", "2/2"];
    case "COVAXIN":
      return ["1/2", "2/2", "3/3", "4/4", "5/5", "6/6"];
    case "MODERNA COVID-19 (mRNA-1273)":
      return ["1/2", "2/2", "3/3", "4/4", "5/5", "6/6"];
    default:
      return ["Select Vaccine"];
  }
}

function BookingForm() {

  const vaccLocation = [
    "Dulwich College Yangon - Pun Hlaing Campus\nPun Hlaing Estate Avenue\nHlaing Thar Yar Township\nYangon",
    "Galaxy Tower 4 - Parking Lot\nStar City Estate\nThanlyin Township\nYangon",
    "No 14/1D  Khine Shwe War Street\nbetween 73 and 74 street\nChan Mya Tharzi Township\nMandalay",
    "117/0-119/9 Taunggyi-Hpone Main Road\nTaunggyi Township\nShan",
    "Pun Hlaing Estate Avenue\nHlaing Thar Yar Township\nYangon",
    "No 14/A Taw Win Street \nCorner of Ambassador St and Taw Win St\nDagon Township\nYangon",
    "No 34 Bandoola Street\n45 Quarter\nNorth Dagon Township\nYangon",
    "No. 97 73 Street\nBet 31 & 32 Street\nChan Aye Tharzan Township\nMandalay",
    "No.310 East Circular Street\nThirt Taw Quarter\nTaunggyi Township\nShan",
  ];
  var validations = {
    employeeId: "Employee ID",
    nationality: "Nationality",
    dob: "Date of birth",
    gender: "Gender",
    phone: "Phone Number",
    email: "Email",
    center: "Center",
    fathername: "Father Name",
    permanentaddress: "Permanent Address",
    township: "Township",
    state: "State",
    mccocId: "CID",
    passport: "Passport",
    paymentstatus: "Payment Status",
    vaccinationcity: "Vaccination City",
    bookeddate: "Booked Date",
    bookedslot: "Booked Slot",
    registrationstatus: "Registration Status",
    dose: "Dose",
    vaccinationstatus: "Vaccination Status",
  };
  const timeSlotsArray = [
    "12:00AM - 1:00AM",
    "1:00AM - 2:00AM",
    "2:00AM - 3:00AM",
    "3:00AM - 4:00AM",
    "4:00AM - 5:00AM",
    "5:00AM - 6:00AM",
    "6:00AM - 7:00AM",
    "7:00AM - 8:00AM",
    "8:00AM - 9:00AM",
    "9:00AM - 10:00AM",
    "10:00AM - 11:00AM",
    "11:00AM - 12:00PM",
    "12:00PM - 1:00PM",
    "1:00PM - 2:00PM",
    "2:00PM - 3:00PM",
    "3:00PM - 4:00PM",
    "4:00PM - 5:00PM",
    "5:00PM - 6:00PM",
    "6:00PM - 7:00PM",
    "7:00PM - 8:00PM",
    "8:00PM - 9:00PM",
    "9:00PM - 10:00PM",
    "10:00PM - 11:00PM",
    "11:00PM - 12:00AM",
  ];
  const returnCenterName = (id) => {
    switch (id) {
      case "01":
        return "Pun Hlaing Vaccination Center (Dulwich College - Hlaing Tharyar)";
      case "02":
        return "Pun Hlaing Vaccination Center (Star City)";
      case "03":
        return "Pun Hlaing Hospital (Mandalay)";
      case "04":
        return "Pun Hlaing Hospital (Taunggyi)";
      case "05":
        return "Pun Hlaing Hospitals (Yangon - Hlaing Tharyar)";
      case "06":
        return "Pun Hlaing Clinic (TWC)";
      case "07":
        return "Pun Hlaing Clinic (NDC)";
      case "08":
        return "Pun Hlaing Hospital (MDY)";
      case "09":
        return "Pun Hlaing Hospital (TGY)";
      case "10":
        return "Pun Hlaing - Star City Clinic (SCC)";
      case "11":
        return "Pun Hlaing Family Clinics - Sanchaung (SCG)";
      case "12":
        return "Pun Hlaing Family Clinics - Thingangyun";
      default:
        return "Select Center";
    }
  };

  const centers = [
    { centerId: "01", centerName: "Pun Hlaing Vaccination Center (Dulwich College - Hlaing Tharyar)" },
    { centerId: "02", centerName: "Pun Hlaing Vaccination Center (Star City)" },
    { centerId: "03", centerName: "Pun Hlaing Hospital (Mandalay)" },
    { centerId: "04", centerName: "Pun Hlaing Hospital (Taunggyi)" },
    { centerId: "05", centerName: "Pun Hlaing Hospitals (Yangon - Hlaing Tharyar)" },
    { centerId: "06", centerName: "Pun Hlaing Clinic (TWC)" },
    { centerId: "07", centerName: "Pun Hlaing Clinic (NDC)" },
    // { centerId: "08", centerName: "Pun Hlaing Hospital (MDY)" },
    // { centerId: "09", centerName: "Pun Hlaing Hospital (TGY)" },
    { centerId: "10", centerName: "Pun Hlaing - Star City Clinic (SCC)" },
    { centerId: "11", centerName: "Pun Hlaing Family Clinics - Sanchaung (SCG)" },
    { centerId: "12", centerName: "Pun Hlaing Family Clinics - Thingangyun" }
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState("Male");
  const [fullname, setFullName] = useState("");
  const [fathername, setFathername] = useState("");
  const [genderDrop, setGenderDrop] = useState(false);
  const [dob, setDob] = useState(new Date());
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+95");
  const [permanentaddress, setPermanentAddress] = useState("");
  const [township, setTownship] = useState("");
  const [state, setState] = useState("");
  const [paymentStatusDrop, setPaymentStatusDrop] = useState(false);
  const [paymentstatus, setPaymentStatus] = useState("Unpaid");
  const [vaccinationcity, setVaccinationCity] = useState("");
  const [bookeddate, setBookedDate] = useState(moment().format("YYYY-MM-DD"));
  const [bookedslot, setBookedSlot] = useState("12:00AM - 1:00AM");
  const [bookedSlotDrop, setBookedSlotDrop] = useState(false);
  const [registrationStatusDrop, setRegistrationStatusDrop] = useState(false);
  const [registrationstatus, setRegistrationStatus] = useState("Unregistered");
  const [vaccinationstatus, setVaccinationStatus] = useState("Not Vaccinated");
  const [vaccinationStatusDrop, setVaccinationStatusDrop] = useState(false);
  const [doseDrop, setDoseDrop] = useState(false);
  const [doses, setDoses] = useState([]);
  const [dose, setDose] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [companyDrop, setCompanyDrop] = useState(false);
  const [company, setCompany] = useState("");
  const [companyArray, setCompanyArray] = useState([]);
  const [vaccine, setVaccine] = useState("BIBP/Sinopharm");
  const [vaccineDrop, setVaccineDrop] = useState(false);
  const [nationality, setNationality] = useState("Myanmar");
  const [employeeId, setEmployeeId] = useState("");
  const [markVoid, setMarkVoid] = useState(false);
  const [passport, setPassport] = useState("");
  const [center, setCenter] = useState("01");
  const [centerDrop, setCenterDrop] = useState(false);
  const [mccocId, setMccocId] = useState("");

  const [spinnerCheck, setSpinnerCheck] = useState(false);


  useEffect(() => {
    // console.log({ vaccine })
    const dosesForVaccine = vaccineDoses(vaccine)
    setDose(dosesForVaccine[0]);
    setDoses(dosesForVaccine);
  }, [vaccine])

  useEffect(() => {
    let array = [];
    for (let i = 0; i < companies.length; i++) {
      var obj = {
        label: companies[i],
        value: companies[i],
      };
      array.push(obj);
    }
    setCompanyArray(array);
  }, []);
  const signout = () => {
    Auth.signout();
    window.location.reload();
  };
  const clearForm = () => {
    setFullName("");
    setFathername("");
    setGender("Male");
    setDob("");
    setEmail("");
    setPhone("+95");
    setPermanentAddress("");
    setTownship("");
    setState("");
    setNationality("Myanmar");
    setPassport("");
    setMccocId("");
    setEmployeeId("");
    setCompany("Yoma Bank");
    setInvoiceNo("")
  };
  const submitBooking = async (e) => {
    e.preventDefault();
    let dobRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      try {
        var data = {
          fullname,
          company,
          employeeId,
          nationality,
          dob,
          gender,
          phone,
          email,
          fathername,
          permanentaddress,
          mccocId,
          township,
          state,
          center,
          paymentstatus,
          passport,
          vaccinationcity,
          bookeddate: bookeddate,
          bookedslot,
          registrationstatus,
          dose: dose,
          vaccinationstatus,
          vaccine,
          markVoid,
          invoiceNo
        };
        if (fullname == null || fullname === undefined || fullname === "") {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Name Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (dob == null || dob === undefined || dob === "" || !dobRegex.test(dob)) {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Date of birth Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (phone == null || phone === undefined || phone === "" || phone === "+") {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Phone Number Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          passport == null ||
          passport === undefined ||
          passport === ""
        ) {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`NRC/Passport Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (email !== "") {
          setSpinnerCheck(false)
          setIsLoading(false);
          const format =
            /(^(?!.*__.*)[a-z0-9]{2,253}(_?)[a-z0-9]+(?:\.[a-z0-9!#$%&*+\/=?^`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9]*[a-z0-9])?$)/gs;
          if (!email.match(format)) {
            return toast.error(`Invalid Email Format`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else if (invoiceNo.trim() === "") {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Invoice Number Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        try {
          const parsedPhoneNumber = parsePhoneNumber(phone);
          if (!parsedPhoneNumber.isValid()) {
            setSpinnerCheck(false)
            setIsLoading(false);
            return toast.error(`Phone Number Is Incorrect`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (e) {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Phone Number Incorrect ${e.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }


        setIsLoading(true);
        setSpinnerCheck(true);
        var ISSUANCE_API_PATH =
          process.env.REACT_APP_API_URL + "/api/create-booking";
        fetch(ISSUANCE_API_PATH, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setSpinnerCheck(false)

              toast.success(`Booking Submitted Successfully`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              clearForm();

              setIsLoading(false);
            } else {
              setSpinnerCheck(false)
              setIsLoading(false);
              toast.error(`${json.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
      } catch (e) {
        console.log({ e });
        toast.error(e.message);
        setIsLoading(false);
      }

    }
  };

  return (
    <>
      <ToastContainer />
      <CredHeader heading={"Create New Booking"} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Booking Form</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="invoiceNo">Invoice Number<span className="text-danger">*</span></Label>
                      <Input
                        type="text"
                        id="invoiceNo"
                        placeholder="Enter Invoice Number"
                        value={invoiceNo}
                        onChange={(e) => setInvoiceNo(e.target.value)}
                      />
                    </Col>


                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">
                        Name (အမည်)<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        value={fullname}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="Enter Your Full Name Here"
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Father Name</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Father Name Here"
                        value={fathername}
                        onChange={(e) => setFathername(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Gender
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={genderDrop}
                              toggle={() => setGenderDrop(!genderDrop)}
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {gender}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem onClick={() => setGender("Male")}>
                                  Male
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setGender("Female")}
                                >
                                  Female
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setGender("Other")}
                                >
                                  Other
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Date of birth<span className="text-danger">*</span></Label>
                      <Input
                        type="date"
                        placeholder="Enter Your Father Name Here"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Email (အီးမေးလ်)</Label>
                      <Input
                        type="email"
                        placeholder="Enter Your Email Here"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">
                        Phone Number (ဖုန်းနံပါတ်)
                        <span className="text-danger">*</span>
                      </Label>
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        country={"mm"}
                        value={phone}
                        onChange={(e) => {
                          setPhone("+" + e);
                        }}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Permanent Address</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Address Here"
                        value={permanentaddress}
                        onChange={(e) => setPermanentAddress(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Township</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Township Here"
                        value={township}
                        onChange={(e) => setTownship(e.target.value)}
                      />
                    </Col>

                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">State</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your State Here"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Nationality</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your State Here"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">
                        NRC / Passport<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Enter Your NRC/Passport Here"
                        value={passport}
                        onChange={(e) => setPassport(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">CID</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your CID Here"
                        value={mccocId}
                        onChange={(e) => setMccocId(e.target.value)}
                      />
                    </Col>
                    {/* <Col xl="6" md="6" sm="12" className="pt-2">
                <Label for="exampleEmail">
                  Serial
                </Label>
                <Input
                  type="number"
                  placeholder="Enter Serial Here"
                  min={0}
                  defaultValue={serial}
                  onChange={(e) => setSerial(e.target.value)}
                />
              </Col> */}
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Payment Status
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={paymentStatusDrop}
                              toggle={() =>
                                setPaymentStatusDrop(!paymentStatusDrop)
                              }
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {paymentstatus}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() => setPaymentStatus("Paid")}
                                >
                                  Paid
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setPaymentStatus("Unpaid")}
                                >
                                  Unpaid
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Center Name
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={centerDrop}
                              toggle={() => setCenterDrop(!centerDrop)}
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                                style={{
                                  maxWidth: "250px",
                                  whiteSpace: " nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {returnCenterName(center)}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                {centers.map((center, index) => (
                                  <DropdownItem
                                    key={index}
                                    onClick={() => {
                                      setCenter(center.centerId)
                                    }}
                                  >
                                    {center.centerName}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    {/* <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Vaccination Location
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={vaccinationLocationDrop}
                              toggle={() =>
                                setVaccinationLocationDrop(
                                  !vaccinationLocationDrop
                                )
                              }
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                                style={{
                                  width: "430px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {vaccinationlocation}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                {vaccLocation.map((location, index) => (
                                  <DropdownItem
                                    onClick={() =>
                                      setVaccinationLocation(location)
                                    }
                                    key={index}
                                  >
                                    {location}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col> */}
                    {/* <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Vaccination City</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Vaccination City Here"
                        defaultValue={vaccinationcity}
                        onChange={(e) => setVaccinationCity(e.target.value)}
                      />
                    </Col> */}
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-vaccinename"
                        >
                          Vaccine
                        </label>
                        <div id="input-eventtype">
                          <Dropdown
                            isOpen={vaccineDrop}
                            toggle={() => setVaccineDrop(!vaccineDrop)}
                          >
                            <DropdownToggle
                              color="white"
                              caret
                              className="dropdown"
                            >
                              {vaccine}
                            </DropdownToggle>
                            <DropdownMenu className="menu-dropdown">
                              <DropdownItem
                                onClick={() => setVaccine("Pfizer-BioNTech")}
                              >
                                Pfizer-BioNTech
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setVaccine("Spikevax (previously Moderna)")
                                }
                              >
                                Spikevax (previously Moderna)
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setVaccine(
                                    "Vaxzevria (previously AstraZeneca)"
                                  )
                                }
                              >
                                Vaxzevria (previously AstraZeneca)
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setVaccine("Janssen (Johnson & Johnson)")
                                }
                              >
                                Janssen (Johnson & Johnson)
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => setVaccine("BIBP/Sinopharm")}
                              >
                                BIBP/Sinopharm
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => setVaccine("COVISHIELD™")}
                              >
                                COVISHIELD™
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => setVaccine("CoronaVac")}
                              >
                                CoronaVac
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setVaccine("COVAXIN")
                                }
                              >
                                COVAXIN
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setVaccine("MODERNA COVID-19 (mRNA-1273)")
                                }
                              >
                                MODERNA COVID-19 (mRNA-1273)
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-vaccinename"
                        >
                          Company
                        </label>
                        {/* <div id="input-eventtype">
                          <Dropdown
                            isOpen={companyDrop}
                            toggle={() => setCompanyDrop(!companyDrop)}
                          >
                            <DropdownToggle
                              color="white"
                              caret
                              className="dropdown"
                            >
                              {company}
                            </DropdownToggle>
                            <DropdownMenu className="menu-dropdown">
                              {companies.map((companyName, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() => setCompany(companyName)}
                                >
                                  {companyName}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </div> */}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{ label: company, value: company }}
                          options={companyArray}
                          onChange={(e) => {
                            setCompany(e.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Employee Id</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Employee Id"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Booked Date</Label>
                      <Input
                        type="date"
                        placeholder="Enter Your Booked Date"
                        value={bookeddate}
                        onChange={(e) => setBookedDate(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <label className="input-dose" htmlFor="f5">
                          Time Slot
                        </label>
                        <div id="f5">
                          <Dropdown
                            isOpen={bookedSlotDrop}
                            toggle={() => setBookedSlotDrop(!bookedSlotDrop)}
                          >
                            <DropdownToggle
                              color="white"
                              caret
                              className="dropdown"
                            >
                              {bookedslot}
                            </DropdownToggle>
                            <DropdownMenu
                              className="menu-dropdown"
                              style={{ overflow: "auto", maxHeight: "25vh" }}
                            >
                              {timeSlotsArray.map((slot, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() => setBookedSlot(slot)}
                                >
                                  {slot}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Registration Status
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={registrationStatusDrop}
                              toggle={() =>
                                setRegistrationStatusDrop(
                                  !registrationStatusDrop
                                )
                              }
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {registrationstatus}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() =>
                                    setRegistrationStatus("Registered")
                                  }
                                >
                                  Registered
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    setRegistrationStatus("Unregistered")
                                  }
                                >
                                  Unregistered
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Vaccinated Status
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={vaccinationStatusDrop}
                              toggle={() =>
                                setVaccinationStatusDrop(!vaccinationStatusDrop)
                              }
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {vaccinationstatus}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() =>
                                    setVaccinationStatus("Vaccinated")
                                  }
                                >
                                  Vaccinated
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    setVaccinationStatus("Not Vaccinated")
                                  }
                                >
                                  Not Vaccinated
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>

                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Dose
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={doseDrop}
                              toggle={() => setDoseDrop(!doseDrop)}
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {dose}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                {/* <DropdownItem onClick={() => setDose("1/2")}>
                                  1/2
                                </DropdownItem>
                                <DropdownItem onClick={() => setDose("2/2")}>
                                  2/2
                                </DropdownItem> */}
                                {doses.map((data) => {
                                  return <DropdownItem key={data} onClick={() => setDose(data)}>{data}</DropdownItem>
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>

                  </Row>
                  <Row>
                    <Col className="ml-auto" md="4">
                      <Button
                        className="btn btn-success btn-md btn-block"
                        onClick={submitBooking}
                        disabled={isLoading}
                        type="button"
                      >
                        {!spinnerCheck ? ("Submit Record") : <div>Submitting Record  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}


                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default BookingForm;
