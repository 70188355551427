const jwt = require("jsonwebtoken");

const Auth = {
  authenticate(token, exp) {
    localStorage.setItem("itoken", token);
    localStorage.setItem("exp", exp);
  },

  signout() {
    localStorage.removeItem("itoken");
    localStorage.removeItem("name");
    localStorage.removeItem("image");
    localStorage.removeItem("firstLoad");
    localStorage.removeItem("exp");
    localStorage.removeItem("admin_center");
    localStorage.removeItem("table");
    localStorage.removeItem("scope");
  },

  getAuth() {
    //TODO: verify token for more security
    try {
      let token = localStorage.getItem("itoken");
      let decodedToken = jwt.decode(token);
      let tokenRole = decodedToken["role"];
      return tokenRole;
    } catch (error) {
      return undefined;
    }
  },

  getToken() {
    let nowUnixEpoch = Math.round(Date.now() / 1000);
    let expUnixEpoch = Number(localStorage.getItem("exp"));
    if (expUnixEpoch - nowUnixEpoch <= 120) {
      return undefined;
    } else {
      return localStorage.getItem("itoken");
    }
  },
};

export default Auth;
