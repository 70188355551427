import React, { useState } from "react";
import { useTable } from "react-table";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber, parse } from 'libphonenumber-js'



import { Redirect } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Button,
  FormGroup,
  Dropdown,
  Media,
  Pagination,
  PaginationItem,
  Table,
  PaginationLink,
  Progress,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Label,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
// core components
import Header from "../components/Headers/BookingHeader.js";
import Auth from "../helpers/Auth";
import { arrayExpression } from "@babel/types";
import CanScope from "./../helpers/ScopePermissions";
import { useEffect } from "react";
import axios from "axios";
import { from } from "form-data";
import { VACCINATION_CERTIFICATE_ISSUER } from "../helpers/consts";
var moment = require("moment");
const fetch = require("node-fetch");
Modal.setAppElement("#root");

let bookingsList = [];

function ReactTable({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <div
      style={{
        display: "block",
        maxWidth: "100%",
        overflowX: "scroll",
        overflowY: "hidden",
        borderBottom: "1px solid grey",
      }}
      className="rotate-table"
      id="record-table"
    >
      <table
        className="align-items-center table-flush table responsive rotate-table"
        {...getTableProps()}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                column.hideHeader === false ? null : (
                  <th scope="col" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export const signout = () => {
  Auth.signout();
  window.location.reload();
};

const TOTAL_NUMBER_OF_RECORD_PER_PAGE = 500;
var pageNo = 1;
var bookingFilteredList = [];

const Tables = (props) => {
  var isPaginationApplied = false;
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterKeywords, setFilterKeywords] = useState({});

  // For Issue Modal
  const [issueModal, setIssueModal] = useState(false);
  const [arrivedModal, setArrivedModal] = useState(false);
  const [virusName, setVirusName] = useState("SARS-CoV-2 (COVID-19)");
  const [VaccineName, setVaccineName] = useState("BIBP/Sinopharm");
  const [VaccineLotsNo, setVaccineLotsNo] = useState("202107B1936");
  const [Manufacturer, setManufacturer] = useState(
    "Beijing Institute of Biological Products Co., Ltd. (BIBP)"
  );
  const [organization, setOrganization] = useState(
    "Pun Hlaing Hospitals"
  );
  const [IssueDate, setIssueDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const [issueDateISO, setIssueDateIso] = useState(
    moment(IssueDate).toISOString(true)
  );
  const [clickedRow, setClickedRow] = useState({});
  const [issueSuccess, setIssueSuccess] = useState(undefined);
  const [issueLoading, setIssueLoading] = useState(false);
  const [VaccincationDropDownOpen, setVaccincationDropDownOpen] =
    useState(false);
  const toggleVaccincationDropDown = () =>
    setVaccincationDropDownOpen((prevState) => !prevState);
  const handleVaccincationDropDownSelect = (e) => {
    setManufacturer(vaccinationManufacturerMap(e));
    setVaccineName(e);
  };

  //todo: Arrived button spinner state
  const [isArrivedDataLoading, setIsArrivedDataLoading] = useState(false)
  const [spinnerCheck, setSpinnerCheck] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)



  //todo: Edit button spinner state
  const [isEditDataLoading, setIsEditDataLoading] = useState(false)


  //new add slotsNo code
  const [VaccincationLotsNoDropDownOpen, setVaccincationLotsNoDropDownOpen] =
    useState(false);
  const toggleVaccincationLotsNoDropDown = () =>
    setVaccincationLotsNoDropDownOpen((prevState) => !prevState);
  const handleVaccincationLotsNoDropDownSelect = (e) => {
    // setManufacturer(vaccinationManufacturerMap(e));
    setVaccineLotsNo(e);
  };


  // end here
  function vaccinationManufacturerMap(name) {
    switch (name) {
      case "Pfizer-BioNTech":
        return "BioNTech Manufacturing GmbH";

      case "Spikevax (previously Moderna)":
        return "Moderna US, Inc";

      case "Vaxzevria (previously AstraZeneca)":
        return "AstraZeneca Ab";

      case "Janssen (Johnson & Johnson)":
        return "Janssen-Cilag International NV";

      case "BIBP/Sinopharm":
        return "Beijing Institute of Biological Products Co., Ltd. (BIBP)";

      case "COVISHIELD™":
        return "Serum Institute of India Pvt. Ltd";

      case "CoronaVac":
        return "Sinovac Life Sciences Co., Ltd";

      case "COVAXIN":
        return "Bharat Biotech"

      case "MODERNA COVID-19 (mRNA-1273)":
        return "Moderna US, Inc"

      default:
        return "Not Found";
    }
  }

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOKIsOpen, setModalOKIsOpen] = useState(false);
  const [modalNotOKIsOpen, setModalNotOKIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [genderDrop, setGenderDrop] = useState(false);
  const [gender, setGender] = useState("Male");
  const [dob, setDob] = useState("");
  const [docId, setDocId] = useState("");
  const [mccocId, setMccocId] = useState("");
  const [dose, setDose] = useState("1/2");
  const [vaccStatus, setVaccStatus] = useState("Not Vaccinated");
  const [vaccLocDrop, setVaccLocDrop] = useState(false);
  const [fatherName, setFatherName] = useState("");
  const [vaccloc, setVaccLoc] = useState("");
  const [vacclocId, setVaccLocId] = useState("");
  const [nationality, setNationality] = useState("");
  const [adminCenter, setAdminCenter] = useState("")

  const [selectedtimeSlot, setSelectedTimeSlot] = useState("");
  const [bookedDate, setBookedDate] = useState("");
  const [editIndex, setEditIndex] = useState("");
  const [timeSlotDrop, setTimeSlotDrop] = useState(false);
  const [toggleMarkVoid, setToggleMarkVoid] = useState(false);
  const [markVoidId, setMarkVoidId] = useState(null);
  const vaccLocation = [
    "Dulwich College Yangon - Pun Hlaing Campus Pun Hlaing Estate Avenue Hlaing Thar Yar Township Yangon",
    "Galaxy Tower 4 - Parking Lot Star City Estate Thanlyin Township Yangon",
    "No 14/1D  Khine Shwe War Street between 73 and 74 street Chan Mya Tharzi Township Mandalay",
    "117/0-119/9 Taunggyi-Hpone Main Road Taunggyi Township Shan",
    "Pun Hlaing Estate Avenue Hlaing Thar Yar Township Yangon",
    "No 14/A Taw Win Street  Corner of Ambassador St and Taw Win St Dagon Township Yangon",
    "No 34 Bandoola Street 45 Quarter North Dagon Township Yangon",
    "No. 97 73 Street Bet 31 & 32 Street Chan Aye Tharzan Township Mandalay",
    "No.310 East Circular Street Thirt Taw Quarter Taunggyi Township Shan",
  ];
  const timeSlotsArray = [
    "12:00AM - 1:00AM",
    "1:00AM - 2:00AM",
    "2:00AM - 3:00AM",
    "3:00AM - 4:00AM",
    "4:00AM - 5:00AM",
    "5:00AM - 6:00AM",
    "6:00AM - 7:00AM",
    "7:00AM - 8:00AM",
    "8:00AM - 9:00AM",
    "9:00AM - 10:00AM",
    "10:00AM - 11:00AM",
    "11:00AM - 12:00PM",
    "12:00PM - 1:00PM",
    "1:00PM - 2:00PM",
    "2:00PM - 3:00PM",
    "3:00PM - 4:00PM",
    "4:00PM - 5:00PM",
    "5:00PM - 6:00PM",
    "6:00PM - 7:00PM",
    "7:00PM - 8:00PM",
    "8:00PM - 9:00PM",
    "9:00PM - 10:00PM",
    "10:00PM - 11:00PM",
    "11:00PM - 12:00AM",
  ];
  const centers = [
    { centerId: "01", centerName: "Pun Hlaing Vaccination Center (Dulwich College - Hlaing Tharyar)" },
    { centerId: "02", centerName: "Pun Hlaing Vaccination Center (Star City)" },
    { centerId: "03", centerName: "Pun Hlaing Hospital (Mandalay)" },
    { centerId: "04", centerName: "Pun Hlaing Hospital (Taunggyi)" },
    { centerId: "05", centerName: "Pun Hlaing Hospitals (Yangon - Hlaing Tharyar)" },
    { centerId: "06", centerName: "Pun Hlaing Clinic (TWC)" },
    { centerId: "07", centerName: "Pun Hlaing Clinic (NDC)" },
    // { centerId: "08", centerName: "Pun Hlaing Hospital (MDY)" },
    // { centerId: "09", centerName: "Pun Hlaing Hospital (TGY)" },
    { centerId: "10", centerName: "Pun Hlaing - Star City Clinic (SCC)" },
    { centerId: "11", centerName: "Pun Hlaing Family Clinics - Sanchaung (SCG)" },
    { centerId: "12", centerName: "Pun Hlaing Family Clinics - Thingangyun" }
  ];
  const returnCenterName = (id) => {
    switch (id) {
      case 1:
        return "Pun Hlaing Vaccination Center (Dulwich College - Hlaing Tharyar)";
      case 2:
        return "Pun Hlaing Vaccination Center (Star City)";
      case 3:
        return "Pun Hlaing Hospital (Mandalay)";
      case 4:
        return "Pun Hlaing Hospital (Taunggyi)";
      case 5:
        return "Pun Hlaing Hospitals (Yangon - Hlaing Tharyar)";
      case 6:
        return "Pun Hlaing Clinic (TWC)";
      case 7:
        return "Pun Hlaing Clinic (NDC)";
      // case 8:
      //   return "Pun Hlaing Hospital (MDY)";
      // case 9:
      //   return "Pun Hlaing Hospital (TGY)";
      case 10:
        return "Pun Hlaing - Star City Clinic (SCC)";
      case 11:
        return "Pun Hlaing Family Clinics - Sanchaung (SCG)";
      case 12:
        return "Pun Hlaing Family Clinics - Thingangyun";
      default:
        return "Select Center";
    }
  };
  const [DoseDropDownOpen, setDoseDropDownOpen] = useState(false);
  const toggleDoseDropDown = () =>
    setDoseDropDownOpen((prevState) => !prevState);
  const handleDoseDropDownSelect = (e) => {
    setDose(e);
  };

  const [VaccStatusDropDownOpen, setVaccStatusDropDownOpen] = useState(false);
  const toggleVaccStatusDropDown = () =>
    setVaccStatusDropDownOpen((prevState) => !prevState);
  const handleVaccStatusDropDownSelect = (e) => {
    setVaccStatus(e);
  };

  React.useEffect(() => {
    let firstLoad = localStorage.getItem("firstLoad");
    if (firstLoad === "true") {
      let fromDate = moment().format("YYYY-MM-DD");
      let data = loadInitalBookingList(fromDate, fromDate);
      if (data !== undefined) {
        data.then((d) => {
          localStorage.setItem("table", JSON.stringify(d));
          bookingsList = d;
          setTableData(bookingsList);
        });
      }
      localStorage.setItem("firstLoad", false);
    } else {
      let t = localStorage.getItem("table");
      bookingsList = JSON.parse(t);
      setTableData(bookingsList);
    }
  }, []);
  useEffect(() => {
    const conent = document.querySelector("#record-table");
    conent.scrollLeft = conent.scrollWidth - conent.clientWidth;
  }, [tableData]);

  const registerBooking = () => {
    setSubmitButtonDisabled(true)
    setSpinnerCheck(true)
    let rowIndex = clickedRow.rowIndex;
    let cell = clickedRow.cell;
    let booking = bookingsList[rowIndex];

    //Validation
    let dobRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (docId == null || docId === undefined || docId === "") {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Document ID Is Required.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (dob == null || dob === undefined || dob === "" || !dobRegex.test(dob)) {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Date of birth Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (phone == null || phone === undefined || phone === "" || phone === "+") {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Phone Number Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (adminCenter == null || adminCenter === undefined || adminCenter === "" || adminCenter === "Select Center") {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Admin center is required.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }



    try {
      const parsedPhoneNumber = parsePhoneNumber(phone);
      if (!parsedPhoneNumber.isValid()) {
        setSpinnerCheck(false)
        setSubmitButtonDisabled(false)
        return toast.error(`Phone Number Is Incorrect`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (e) {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Phone Number Incorrect ${e.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }




    try {
      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {

        let data = {
          phone,
          name,
          dob,
          gender,
          nationality,
          docId,
          dose,
          adminCenter
        }



        setIssueLoading(true);
        var API_PATH = process.env.REACT_APP_API_URL + "/api/bookingregister";
        fetch(API_PATH, {
          method: "POST",
          body: JSON.stringify({ bookingId: booking.bookingId, data }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTableData([]);
              booking.registrationstatus = "Arrived";
              bookingsList[rowIndex] = booking;
              setTableData(bookingsList);
              // todo: set state to empty after successfull submission
              setPhone("")
              setName("")
              setDob("")
              setGender("")
              setNationality("")

              setDocId("")
              setDose("")
              setAdminCenter("")
              setIsArrivedDataLoading(false)



              setArrivedModal((prev) => !prev);
              setIssueLoading(false);
              setModalOKIsOpen(true);
              setSubmitButtonDisabled(false)
              setSpinnerCheck(false)


            } else {
              setSubmitButtonDisabled(false)
              setSpinnerCheck(false)
              alert("Unable register! " + json.message);
            }
          });
      }
    } catch (error) {
      console.log(error.message);
      // setIsLoading(false);
    }
  };

  const openIssueModal = (rowIndex, cell) => {
    setClickedRow({ rowIndex, cell });
    setIssueModal((prev) => !prev);
    setSubmitButtonDisabled(false)
  };

  const ArrivedModelOpen = async (rowIndex, cell) => {
    let booking = bookingsList[rowIndex]
    setClickedRow({ rowIndex, cell });
    let token = Auth.getToken();
    setArrivedModal((prev) => !prev);
    // todo: removing api call

    setPhone(booking.phone)
    setName(booking.fullname)
    setDob(booking.dob)
    setGender(booking.gender)
    setNationality(booking.nationality)

    setDocId(booking.documentId)
    setDose(booking.dose)
    setAdminCenter(booking.centerName ? booking.centerName : returnCenterName(""))

    setIsArrivedDataLoading(true)
    setSubmitButtonDisabled(false)

  }

  const markVaccinated = async () => {

    if (!issueDateISO) {
      return toast.error(`Please Select Issue Date`);
    }

    setSubmitButtonDisabled(true)
    setSpinnerCheck(true)
    setIssueLoading(true);
    let rowIndex = clickedRow.rowIndex;
    let cell = clickedRow.cell;
    let booking = bookingsList[rowIndex];

    try {

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {



        let adminCenterName = booking.centerName;

        // Patch to fix old db schemas (backward compatibility)
        if (!adminCenterName) {
          let centerId = parseInt(booking.centerId)
          adminCenterName = returnCenterName(centerId)
        }


        let documentId = booking.documentId ?? "";
        let documentType = documentId.toString().includes("/")
          ? "National Registration Card (NRC)"
          : "Passport";

        if (VaccineName == "BIBP/Sinopharm") {
          var covidPass = {
            "Lots No": VaccineLotsNo,
            "Virus Name": virusName,
            "Vaccine Name": VaccineName,
            "Vaccine Manufacturer": Manufacturer,
            Dose: booking.dose,
            "Administrating Center": adminCenterName,
            "Staff Name": localStorage.getItem("name"),
            "Full Name": booking.fullname,
            "Birth Date": booking.dob,
            Nationality: booking.nationality,
            Gender: booking.gender,
            "Document Type": documentType,
            "Document Id": documentId,
            "Issue Time": issueDateISO,
            Country: "Myanmar",
            "Vaccination Certificate Issuer": VACCINATION_CERTIFICATE_ISSUER,
          };

        }
        else {
          var covidPass = {
            "Virus Name": virusName,
            "Vaccine Name": VaccineName,
            "Vaccine Manufacturer": Manufacturer,
            Dose: booking.dose,
            "Administrating Center": adminCenterName,
            "Staff Name": localStorage.getItem("name"),
            "Full Name": booking.fullname,
            "Birth Date": booking.dob,
            Nationality: booking.nationality,
            Gender: booking.gender,
            "Document Type": documentType,
            "Document Id": documentId,
            "Issue Time": issueDateISO,
            Country: "Myanmar",
            "Vaccination Certificate Issuer": VACCINATION_CERTIFICATE_ISSUER,
          };
        }


        var API_PATH = process.env.REACT_APP_API_URL + "/api/markvaccinated";

        fetch(API_PATH, {
          method: "POST",
          body: JSON.stringify({
            bookingId: booking.bookingId,
            credential: covidPass,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTableData([]);
              booking.vaccinationstatus = "Vaccinated";
              bookingsList[rowIndex] = booking;
              setTableData(bookingsList);
              setIssueModal(false);
              setIssueLoading(false);
              setModalOKIsOpen(true);
              setSpinnerCheck(false)
              setSubmitButtonDisabled(false)
            } else {

              setSpinnerCheck(false)
              setSubmitButtonDisabled(false)
              alert("Unable to update! " + json.message);
            }
          });
      }
    } catch (error) {
      setIssueModal(false);
      setIssueLoading(false);
      setModalNotOKIsOpen(true);
      console.log(error.message);
    }
  };

  const markStatusVoid = () => {
    let booking = tableData[markVoidId];
    try {
      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        var API_PATH =
          process.env.REACT_APP_API_URL + `/api/markVoid/${booking.bookingId}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              localStorage.setItem("firstLoad", true);
              // let allRecords = tableData;
              if (tableData.length === 1) {
                setTableData([]);
                setMarkVoidId(null);
              } else {
                setTableData([]);
                tableData.splice(markVoidId, 1);
                setMarkVoidId(null);

                setTableData(tableData);
              }
              setToggleMarkVoid(false);
              setModalOKIsOpen(true);
            } else {
              alert("Unable to Set Void! " + json.message);
            }
          });
      }
    } catch (error) {
      console.log(error.message);
      // setIsLoading(false);
    }
  };

  const editRecord = () => {
    let booking = bookingsList[editIndex];
    let dobRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (docId == null || docId === undefined || docId === "") {
      return toast.error(`Document ID Is Required.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (dob == null || dob === undefined || dob === "" || !dobRegex.test(dob)) {
      return toast.error(`Date of birth Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (phone == null || phone === undefined || phone === "" || phone === "+") {
      return toast.error(`Phone Number Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    try {
      const parsedPhoneNumber = parsePhoneNumber(phone);
      if (!parsedPhoneNumber.isValid()) {
        return toast.error(`Phone Number Is Incorrect`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (e) {
      return toast.error(`Phone Number Incorrect ${e.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }


    try {
      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        // Getting centerId from centerName
        // let centerIndex = centers.findIndex((e) => e.centerName == vaccloc);
        // let centerId = centers[centerIndex].centerId
        let centerId = vacclocId;

        // todo: changes vaccinationlocation value and adding center Attribute
        var API_PATH = process.env.REACT_APP_API_URL + "/api/updatebooking";
        fetch(API_PATH, {
          method: "POST",
          body: JSON.stringify({
            centerId: centerId,
            centerName: vaccloc,
            bookingId: booking.bookingId,
            name: name,
            dob: dob,
            phone: phone,
            gender: gender,
            documentId: docId,
            dose: dose,
            vaccinationstatus: vaccStatus,
            mccocId: mccocId,
            fathername: fatherName,
            bookeddate: bookedDate,
            bookedslot: selectedtimeSlot,
            nationality: nationality,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            // todo: changes vaccinationlocation value and adding center Attribute
            if (json.status === 200) {
              setTableData([]);
              setIsEditDataLoading(false)

              booking.centerId = centerId;
              booking.centerName = vaccloc;
              booking.fullname = name;
              booking.dob = dob;
              booking.documentId = docId;
              booking.phone = phone;
              booking.gender = gender;
              booking.dose = dose;
              booking.vaccinationstatus = vaccStatus;
              booking.mccocId = mccocId;
              booking.fathername = fatherName;
              booking.nationality = nationality;
              booking.bookeddate = moment(bookedDate).format("YYYY-MM-DD");
              bookingsList[editIndex] = booking;

              //  console.log("bookingupdate", booking);
              setTableData(bookingsList);
              closeModal();
              setModalOKIsOpen(true);
              resetUpdatedState();
            } else {
              alert("Unable to update! " + json.message);
            }
          });
      }
    } catch (error) {
      console.log(error.message);
      // setIsLoading(false);
    }
  };
  function YMD(date) {
    var year = date.substring(date.lastIndexOf("-") + 1);
    var month = date.substring(date.indexOf("-") + 1, date.lastIndexOf("-"));
    var day = date.substring(0, date.indexOf("-"));
    return [year, month, day].join("-");
  }
  const markEdit = (rowIndex, cell) => {
    let booking = bookingsList[rowIndex];
    setModalEditIsOpen(true);

    // console.log("bookingDetails", booking);
    setName(booking.fullname);
    setPhone(booking.phone);
    setGender(booking.gender);
    setDob(moment(booking.dob).format("YYYY-MM-DD"));
    setDocId(booking.documentId);
    setMccocId(booking.mccocId);
    setFatherName(booking.fathername);
    setVaccLoc(booking.centerName);
    setVaccLocId(booking.centerId)
    setBookedDate(moment(booking.bookeddate).format("YYYY-MM-DD"));
    setSelectedTimeSlot(booking.bookedslot);
    setNationality(booking.nationality)
    if (booking.dose === "" || booking.dose === undefined) {
      setDose("1/2");
    } else {
      setDose(booking.dose);
    }
    if (
      booking.vaccinationstatus === "" ||
      booking.vaccinationstatus == undefined
    ) {
      setVaccStatus("Not Vaccinated");
    } else {
      setVaccStatus(booking.vaccinationstatus);
    }

    setEditIndex(rowIndex);
    setIsEditDataLoading(true)

  };
  const resetUpdatedState = () => {
    setName("");
    setPhone("");
    setGender("Male");
    setDob("");
    setDocId("");
    setMccocId("");
    setDose("1/2");
    setVaccStatus("Not Vaccinated");
    setVaccLocDrop(false);
    setFatherName("");
    setVaccLoc("");
    setVaccLocId("");
    setBookedDate("");
    setNationality("")

  };

  // todo: standarizing the data and time in Registeration App
  var timeFormats = [
    "YYYY-MM-DD[T]HH:mm:ss.SSSZ",
    "MM-DD-YYYY[T]HH:mm:ss.SSSZ",
    "DD-MM-YYYY[T]HH:mm:ss.SSSZ",
    // with forward slash
    "YYYY/MM/DD HH:mm:ss",
    "MM/DD/YYYY HH:mm:ss",
    "DD/MM/YYYY HH:mm:ss",
    // with hyphen
    "YYYY-MM-DD HH:mm:ss",
    "MM-DD-YYYY HH:mm:ss",
    "DD-MM-YYYY HH:mm:ss",
  ];

  var dateFormats = [
    // with forward slash
    "YYYY/MM/DD",
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    // with hyphen
    "YYYY-MM-DD",
    "MM-DD-YYYY",
    "DD-MM-YYYY",
  ];

  const is_date_time = (val) => {
    if (!moment(val, timeFormats, true).isValid()) return false

    return true
  }

  const is_date = (val) => {
    if (!moment(val, dateFormats, true).isValid()) return false

    return true
  }

  const get_local_issue_time = (val) => {
    return moment
      .utc(val, timeFormats)
      .local()
      .format('YYYY-MM-DD HH:mm (G[M]T Z)')
      .toString();
  };

  const parse_date_time = (val) => {
    if (is_date(val)) {
      return moment(moment(val, ["DD-MM-YYYY", "YYYY-MM-DD", "DD/MM/YYYY", "YYYY/MM/DD"])).format("YYYY-MM-DD");
    }

    if (is_date_time(val)) {
      return get_local_issue_time(val);
    }

    return val;
  }



  // todo: end here


  const loadSearchBookingListByPhone = (phone) => {
    try {

      setFilterKeywords({ phone });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);
        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?phone=${phone}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  mccocId: booking.mccocId,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  invoiceNo: booking.invoiceNo,
                };

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }
                bookingsList.push(data);
              });

              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }

    isPaginationApplied = false;

  };

  const loadSearchBookingListByID = (bookingId) => {
    try {

      setFilterKeywords({ bookingId });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);
        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?bookingId=${bookingId}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  fathername: booking.fathername,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  mccocId: booking.mccocId,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  invoiceNo: booking.invoiceNo,
                };

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });
              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }

    isPaginationApplied = false;

  };

  const loadSearchBookingListByInvoiceNo = (invoiceNo) => {
    try {

      setFilterKeywords({ invoiceNo });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);

        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?invoiceNo=${invoiceNo}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  fathername: booking.fathername,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  mccocId: booking.mccocId,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  invoiceNo: booking.invoiceNo,
                };

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });
              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }

    isPaginationApplied = false;

  };

  const loadInitalBookingList = (fromDate, endDate) => {
    //  fromDate = '2021-08-03'
    // endDate = '2021-08-04'
    try {

      setFilterKeywords({ fromDate, endDate });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);

        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?fromDate=${fromDate}&toDate=${endDate}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        return fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  mccocId: booking.mccocId,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  fathername: booking.fathername,
                  invoiceNo: booking.invoiceNo,
                };


                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });

              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
            return bookingsList;
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      return undefined;
    }

    isPaginationApplied = false;

  };

  const loadSearchByDateAndCenter = (forDate, forCenter) => {
    try {

      setFilterKeywords({ forDate, forCenter });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);

        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?forDate=${forDate}&forCenter=${forCenter}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        return fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  mccocId: booking.mccocId,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  fathername: booking.fathername,
                  invoiceNo: booking.invoiceNo,
                };

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });

              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
            return bookingsList;
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      return undefined;
    }

    isPaginationApplied = false;

  };

  const ToggleMarkVoid = (index) => {
    // console.log(index);
    // console.log(tableData[index].bookingId);
    setMarkVoidId(index);
    setToggleMarkVoid(!toggleMarkVoid);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            Cell: ({ cell }) => (
              <Row className="action-button">
                {CanScope("mark_arrived") && (
                  <Row className="ml-0 mr-0">
                    <Button
                      color="default"
                      size="sm"
                      // onClick={() => registerBooking(cell.row.index, cell)}
                      onClick={() => ArrivedModelOpen(cell.row.index, cell)}
                    >
                      Arrived
                    </Button>
                  </Row>
                )}

                {CanScope("edit_details") && (
                  <Row>
                    <Button
                      color="info"
                      size="sm"
                      style={{ marginLeft: 20 }}
                      onClick={() => markEdit(cell.row.index, cell)}
                    >
                      Edit
                    </Button>
                  </Row>
                )}

                {CanScope("mark_vaccinated") && (
                  <Row>
                    <Button
                      color="primary"
                      size="sm"
                      style={{ marginLeft: 35 }}
                      onClick={() => {
                        openIssueModal(cell.row.index, cell);
                      }}
                    //onClick={() => markVaccinated(cell.row.index, cell)}
                    >
                      Mark Vaccinated
                    </Button>
                  </Row>
                )}
                {CanScope("mark_void") && (
                  <Row>
                    <Button
                      color="warning"
                      size="sm"
                      style={{ marginLeft: 35 }}
                      onClick={() => ToggleMarkVoid(cell.row.index)}
                    >
                      Mark Void
                    </Button>
                  </Row>
                )}
              </Row>
            ),
          },
          {
            Header: "Booking ID",
            accessor: "bookingId",
          },
          {
            width: 150,
            Header: "Full Name",
            accessor: "fullname",
          },
          {
            Header: "CID",
            accessor: "mccocId",
          },
          {
            Header: "Dose",
            accessor: "dose",
          },
          {
            Header: "Company",
            accessor: "company",
          },
          {
            Header: "Employee Id",
            accessor: "employeeId",
          },
          {
            Header: "Nationality",
            accessor: "nationality",
          },

          {
            Header: "NRC or Passport Number",
            accessor: "documentId",
          },
          {
            Header: "Gender",
            accessor: "gender",
          },
          {
            Header: "DOB",
            accessor: "dob",
          },
          {
            Header: "Phone Number",
            accessor: "phone",
          },
          {
            Header: "Payment Status",
            accessor: "paymentstatus",
          },
          {
            Header: "Center Name",
            accessor: "centerName",
          },
          {
            Header: "Booked Date",
            accessor: "bookeddate",
          },
          {
            Header: "Booked Timeslot",
            accessor: "bookedslot",
          },
          {
            Header: "Invoice No",
            accessor: "invoiceNo",
          },
          {
            Header: "Registration Status",
            accessor: "registrationstatus",
          },
          {
            Header: "Vaccination Status",
            accessor: "vaccinationstatus",
          },
        ],
      },
    ],
    []
  );

  function closeModal() {
    setIsEditDataLoading(false)
    setModalOKIsOpen(false);
    setModalNotOKIsOpen(false);
    setModalEditIsOpen(false);
  }

  const HandleArrivedClose = () => {
    setArrivedModal(!arrivedModal);
    setSubmitButtonDisabled(true)

    // todo: set state to empty after close Arrived Button
    setPhone("")
    setName("")
    setDob("")
    setGender("")
    setNationality("")
    setDocId("")
    setDose("")
    setAdminCenter("")
    setIsArrivedDataLoading(false)
    setSubmitButtonDisabled(false)



  }

  const loadMore = () => {
    pageNo++;
    getData();
  }

  const getData = () => {

    isPaginationApplied = true;

    filterKeywords.hasOwnProperty("phone") && loadSearchBookingListByPhone(filterKeywords.phone);
    filterKeywords.hasOwnProperty("bookingId") && loadSearchBookingListByInvoiceNo(filterKeywords.bookingId);
    filterKeywords.hasOwnProperty("invoiceNo") && loadSearchBookingListByInvoiceNo(filterKeywords.invoiceNo);
    filterKeywords.hasOwnProperty("fromDate") && loadInitalBookingList(filterKeywords.fromDate, filterKeywords.endDate);
    filterKeywords.hasOwnProperty("forDate") && loadInitalBookingList(filterKeywords.forDate, filterKeywords.forCenter);

  }

  return (
    <>
      <ToastContainer />
      <Modal
        shouldCloseOnOverlayClick={true}
        backdrop="static"
        isOpen={issueModal}
        toggle={() => setIssueModal(!issueModal)}
        size="sm"
        className="credmodal"
        overlayClassName="credoverlay"
      >
        {!issueLoading ? (
          <>
            <Row className="centered-content d-flex justify-content-center">
              <h4>Credential Vaccine Information</h4>
            </Row>
            <br />
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <FormGroup style={{ width: "100%" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-manufacturer"
                >
                  Virus Name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-manufacturer"
                  value={virusName}
                  onChange={(e) => setVirusName(e.target.value)}
                  disabled
                  type="text"
                />
              </FormGroup>
            </Row>
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <FormGroup style={{ width: "100%" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-vaccinename"
                >
                  Vaccination
                </label>
                <div id="input-eventtype">
                  <Dropdown
                    isOpen={VaccincationDropDownOpen}
                    toggle={toggleVaccincationDropDown}
                  >
                    <DropdownToggle color="white" caret className="dropdown">
                      {VaccineName}
                    </DropdownToggle>
                    <DropdownMenu className="menu-dropdown">
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect("Pfizer-BioNTech")
                        }
                      >
                        Pfizer-BioNTech
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect(
                            "Spikevax (previously Moderna)"
                          )
                        }
                      >
                        Spikevax (previously Moderna)
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect(
                            "Vaxzevria (previously AstraZeneca)"
                          )
                        }
                      >
                        Vaxzevria (previously AstraZeneca)
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect(
                            "Janssen (Johnson & Johnson)"
                          )
                        }
                      >
                        Janssen (Johnson & Johnson)
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect("BIBP/Sinopharm")
                        }
                      >
                        BIBP/Sinopharm
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect("COVISHIELD™")
                        }
                      >
                        COVISHIELD™
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect("CoronaVac")
                        }
                      >
                        CoronaVac
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect("COVAXIN")
                        }
                      >
                        COVAXIN
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleVaccincationDropDownSelect("MODERNA COVID-19 (mRNA-1273)")
                        }
                      >
                        MODERNA COVID-19 (mRNA-1273)
                      </DropdownItem>

                    </DropdownMenu>
                  </Dropdown>
                </div>
              </FormGroup>
            </Row>
            {/* //Adding Slots of Sinopharm */}
            {VaccineName == "BIBP/Sinopharm" ?
              (
                <Row
                  className="d-flex justify-content-center"
                  style={{ width: "100%", alignSelf: "center" }}
                >
                  <FormGroup style={{ width: "100%" }}>
                    <label
                      className="form-control-label"
                      htmlFor="input-vaccinename"
                    >
                      Lot No.
                    </label>
                    <div id="input-eventtype">
                      <Dropdown
                        isOpen={VaccincationLotsNoDropDownOpen}
                        toggle={toggleVaccincationLotsNoDropDown}
                      >
                        <DropdownToggle color="white" caret className="dropdown">
                          {VaccineLotsNo}
                        </DropdownToggle>
                        <DropdownMenu className="menu-dropdown">
                          <DropdownItem
                            onClick={() =>
                              handleVaccincationLotsNoDropDownSelect("202107B1936")
                            }
                          >
                            202107B1936
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleVaccincationLotsNoDropDownSelect(
                                "202107B1937"
                              )
                            }
                          >
                            202107B1937
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleVaccincationLotsNoDropDownSelect(
                                "202107B1938"
                              )
                            }
                          >
                            202107B1938
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleVaccincationLotsNoDropDownSelect(
                                "202109B2886"
                              )
                            }
                          >
                            202109B2886
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleVaccincationLotsNoDropDownSelect(
                                "202109B2951"
                              )
                            }
                          >
                            202109B2951
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </FormGroup>
                </Row>
              )
              :
              ("")

            }

            {/* // Code end here */}
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <FormGroup style={{ width: "100%" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-manufacturer"
                >
                  Manufacturer
                </label>
                <Input
                  className="form-control-alternative"
                  disabled={true}
                  id="input-manufacturer"
                  value={Manufacturer}
                  type="text"
                />
              </FormGroup>
            </Row>

            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <FormGroup style={{ width: "100%" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-issue-date"
                >
                  Issue Date
                </label>

                <Input
                  className="form-control-alternative"
                  id="input-issue-date"
                  value={IssueDate}
                  onChange={(e) => {
                    setIssueDate(e.target.value);
                    setIssueDateIso(moment(e.target.value).toISOString(true));
                  }}
                  type="datetime-local"
                />
              </FormGroup>
            </Row>

            <ModalFooter>
              <Button
                color="primary"
                disabled={submitButtonDisabled}
                onClick={() => {
                  markVaccinated();
                }}
              >
                {!spinnerCheck ? ("Submit") : <div>Submit <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}

              </Button>{" "}
              <Button
                color="secondary"
                disabled={submitButtonDisabled}
                onClick={() => {
                  setIssueModal(!issueModal);
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              id="spinner"
              className="spinner-border centered-content"
              role="status"
            />
            <h5 style={{ marginTop: 10 }}>Marking as vaccinated...</h5>
          </div>
        )}
      </Modal>

      {/* ----------------------------------- */}
      <Modal
        shouldCloseOnOverlayClick={true}
        backdrop="static"
        isOpen={arrivedModal}
        toggle={() => setArrivedModal(!arrivedModal)}
        size="sm"
        width="100%"
        className="credmodal"
        overlayClassName="credoverlay"
      >

        <div id="arrived-modal" style={{ width: "100%", overflowX: "hidden", height: "400px", paddingLeft: "25px" }}>
          <Row className="centered-content d-flex justify-content-center">
            <h4>Credential User Information</h4>
          </Row>
          <br />
          {isArrivedDataLoading ? (
            <>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>



                  <Label
                    className="form-control-label"
                    for="exampleEmail">
                    Phone Number
                    <span className="text-danger">*</span>
                  </Label>
                  <PhoneInput
                    inputStyle={{ width: "100%", height: "30px" }}
                    className="form-control form-control-sm"
                    country={"mm"}
                    value={phone}
                    onChange={(e) => {
                      setPhone("+" + e);
                    }}
                  />

                </FormGroup>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-fullname"
                  >
                    Full Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    // disabled={true}
                    id="input-fullname"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-dob"
                  >
                    Birth Date
                  </label>

                  <Input
                    style={{ height: "40px" }}
                    type="date"
                    id="input-dob"
                    className="form-control form-control-sm"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </FormGroup>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <label className="form-control-label" htmlFor="input-gender">
                    Gender
                  </label>
                  <Dropdown
                    isOpen={genderDrop}
                    toggle={() => setGenderDrop(!genderDrop)}
                  >
                    <DropdownToggle color="white" caret className="dropdown">
                      {gender}
                    </DropdownToggle>
                    <DropdownMenu className="menu-dropdown">
                      <DropdownItem onClick={() => setGender("Male")}>
                        Male
                      </DropdownItem>
                      <DropdownItem onClick={() => setGender("Female")}>
                        Female
                      </DropdownItem>
                      <DropdownItem onClick={() => setGender("Other")}>
                        Other
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-nationality"
                  >
                    Nationality
                  </label>
                  <Input
                    className="form-control-alternative"
                    // disabled={true}
                    id="input-nationality"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-docid"
                  >
                    Document Id
                  </label>
                  <Input
                    className="form-control-alternative"
                    // disabled={true}
                    id="input-docid"
                    value={docId}
                    onChange={(e) => setDocId(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-dose"
                  >
                    Dose
                  </label>

                  <Dropdown
                    isOpen={DoseDropDownOpen}
                    toggle={toggleDoseDropDown}
                  >
                    <DropdownToggle color="white" caret className="dropdown">
                      {dose}
                    </DropdownToggle>
                    <DropdownMenu className="menu-dropdown">

                      {["1/1", "1/2", "2/2", "3/3", "4/4", "5/5", "6/6"].map((dose) => {
                        return (
                          <DropdownItem
                            onClick={() => handleDoseDropDownSelect(dose)}
                          >
                            {dose}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>

                </FormGroup>
              </Row>
              <Row
                className="d-flex justify-content-center"
                style={{ width: "100%", alignSelf: "center" }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-admincenter"
                  >
                    Administrating Center
                  </label>
                  <Dropdown
                    isOpen={vaccLocDrop}
                    toggle={() => setVaccLocDrop(!vaccLocDrop)}
                  >
                    <DropdownToggle
                      color="white"
                      caret
                      className="dropdown"
                    >
                      {adminCenter}
                    </DropdownToggle>
                    <DropdownMenu className="menu-dropdown custom-dropdown">
                      {centers.map((center, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            setAdminCenter(center.centerName);
                          }}
                        >
                          {center.centerName}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                </FormGroup>
              </Row>
            </>
          ) : (
            <>
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <br />
              <div class="d-flex justify-content-center">
                <h3>Loading...</h3>
              </div>
            </>
          )}
        </div>


        <ModalFooter>
          <Button
            color="primary"
            disabled={submitButtonDisabled}
            onClick={() => {
              registerBooking()
            }}
          >
            {!spinnerCheck ? ("Submit") : <div>Submit <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}
          </Button>{" "}
          <Button
            color="secondary"
            disabled={submitButtonDisabled}
            onClick={() => {
              HandleArrivedClose()
            }}
          >
            Cancel
          </Button>

        </ModalFooter>

      </Modal>


      {/* ------------------------------------------- */}

      <Header
        filterSearch={loadInitalBookingList}
        filterSearchByDateAndCenter={loadSearchByDateAndCenter}
        deepSearch={loadSearchBookingListByID}
        phoneSearch={loadSearchBookingListByPhone}
        invoiceNoSearch={loadSearchBookingListByInvoiceNo}
      />
      <Modal
        isOpen={toggleMarkVoid}
        toggle={() => setToggleMarkVoid(!toggleMarkVoid)}
        size="sm"
        className="qrmodal width-70"
        overlayClassName="qroverlay"
      >
        <ModalHeader toggle={() => setToggleMarkVoid(!toggleMarkVoid)}>
          Alert
        </ModalHeader>
        <ModalBody>Are you sure to mark this record as void?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => markStatusVoid()}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={() => setToggleMarkVoid(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Modal
          shouldCloseOnOverlayClick={false}
          backdrop="static"
          isOpen={modalNotOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay"
        >
          <div className="centered-content d-flex justify-content-center">
            <h4>Certificate Issuance Failed</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            <p>Unable to Issue Certificate</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={require("../assets/img/theme/cross.png").default}
            />
          </div>
          <br />
          <Button
            style={{
              width: "100%",
              backgroundColor: "#c82606",
              color: "#ffffff",
            }}
            onClick={closeModal}
          >
            Continue
          </Button>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={true}
          backdrop="static"
          isOpen={modalOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay"
        >
          <div className="centered-content d-flex justify-content-center">
            <h4>Operation Successful</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            <p>Operation is successfully completed!</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={require("../assets/img/theme/check.png").default}
            />
          </div>
          <br />
          <Button
            color="success"
            style={{ width: "100%" }}
            onClick={closeModal}
          >
            Continue
          </Button>
        </Modal>

        {/* EDIT DIALOG */}
        <Modal
          shouldCloseOnOverlayClick={true}
          backdrop="static"
          isOpen={modalEditIsOpen}
          contentLabel="My dialog"
        >
          <div className="centered-content d-flex justify-content-center">
            <h4>Update Record</h4>
          </div>
          <br />
          {isEditDataLoading ? (
            <>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <div className="form-outline">
                    <label className="form-label" htmlFor="f1">
                      Name
                    </label>
                    <Input
                      type="text"
                      id="f1"
                      className="form-control form-control-sm"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <div className="form-outline">
                    <label className="form-label" htmlFor="f1">
                      Father Name
                    </label>
                    <Input
                      type="text"
                      id="f1"
                      className="form-control form-control-sm"
                      value={fatherName}
                      onChange={(e) => setFatherName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-outline" style={{ marginTop: 10 }}>
                    <Label for="exampleEmail">
                      Phone Number (ဖုန်းနံပါတ်)
                      <span className="text-danger">*</span>
                    </Label>
                    <PhoneInput
                      inputStyle={{ width: "100%", height: "30px" }}
                      className="form-control form-control-sm"
                      country={"mm"}
                      value={phone}
                      onChange={(e) => {
                        setPhone("+" + e);
                      }}
                    />

                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-outline" style={{ marginTop: 10 }}>
                    <label className="form-label" htmlFor="f2">
                      Gender
                    </label>
                    <Dropdown
                      isOpen={genderDrop}
                      toggle={() => setGenderDrop(!genderDrop)}
                    >
                      <DropdownToggle color="white" caret className="dropdown">
                        {gender}
                      </DropdownToggle>
                      <DropdownMenu className="menu-dropdown">
                        <DropdownItem onClick={() => setGender("Male")}>
                          Male
                        </DropdownItem>
                        <DropdownItem onClick={() => setGender("Female")}>
                          Female
                        </DropdownItem>
                        <DropdownItem onClick={() => setGender("Other")}>
                          Other
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <div className="form-outline" style={{ marginTop: 10 }}>
                    <label className="form-label" htmlFor="f3">
                      DOB<span className="text-danger">*</span>
                    </label>
                    <Input
                      type="date"
                      id="f3"
                      className="form-control form-control-sm"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                    <label className="form-label" htmlFor="f4">
                      Document ID<span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      id="f4"
                      className="form-control form-control-sm"
                      value={docId}
                      required={true}
                      onChange={(e) => setDocId(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                    <label className="form-label" htmlFor="f41">
                      Nationality
                    </label>
                    <Input
                      type="text"
                      id="f41"
                      className="form-control form-control-sm"
                      value={nationality}
                      onChange={(e) => setNationality(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                    <FormGroup>
                      <label className="input-dose" htmlFor="f5">
                        Dose
                      </label>
                      <div id="f5">
                        <Dropdown
                          isOpen={DoseDropDownOpen}
                          toggle={toggleDoseDropDown}
                        >
                          <DropdownToggle color="white" caret className="dropdown">
                            {dose}
                          </DropdownToggle>
                          <DropdownMenu className="menu-dropdown">

                            {["1/1", "1/2", "2/2", "3/3", "4/4", "5/5", "6/6"].map((dose) => {
                              return (
                                <DropdownItem
                                  onClick={() => handleDoseDropDownSelect(dose)}
                                >
                                  {dose}
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                    <label className="form-label" htmlFor="ff4">
                      CID
                    </label>
                    <Input
                      type="text"
                      id="fg4"
                      className="form-control form-control-sm"
                      value={mccocId}
                      onChange={(e) => setMccocId(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                    <FormGroup>
                      <label className="input-dose" htmlFor="f5">
                        Center Name
                      </label>
                      <div id="f5">
                        <Dropdown
                          isOpen={vaccLocDrop}
                          toggle={() => setVaccLocDrop(!vaccLocDrop)}
                        >
                          <DropdownToggle
                            color="white"
                            caret
                            className="dropdown truncate-loc-name"
                          >
                            {vaccloc}
                          </DropdownToggle>
                          <DropdownMenu className="menu-dropdown">
                            {centers.map((center, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => {
                                  setVaccLoc(center.centerName);
                                  setVaccLocId(center.centerId)
                                }}
                              >
                                {center.centerName}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-outline" style={{ marginTop: 10 }}>
                    <label className="form-label" htmlFor="f3">
                      Booked Date
                    </label>
                    <Input
                      type="date"
                      id="f3"
                      className="form-control form-control-sm"
                      value={bookedDate}
                      onChange={(e) => setBookedDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                    <FormGroup>
                      <label className="input-vacc" htmlFor="f6">
                        Vaccination Status
                      </label>
                      <div id="f6">
                        <Dropdown
                          isOpen={VaccStatusDropDownOpen}
                          toggle={toggleVaccStatusDropDown}
                        >
                          <DropdownToggle color="white" caret className="dropdown">
                            {vaccStatus}
                          </DropdownToggle>
                          <DropdownMenu className="menu-dropdown">
                            <DropdownItem
                              onClick={() =>
                                handleVaccStatusDropDownSelect("Vaccinated")
                              }
                            >
                              Vaccinated
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleVaccStatusDropDownSelect("Not Vaccinated")
                              }
                            >
                              Not Vaccinated
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                    <FormGroup>
                      <label className="input-dose" htmlFor="f5">
                        Time Slot
                      </label>
                      <div id="f5">
                        <Dropdown
                          isOpen={timeSlotDrop}
                          toggle={() => setTimeSlotDrop(!timeSlotDrop)}
                        >
                          <DropdownToggle color="white" caret className="dropdown">
                            {selectedtimeSlot}
                          </DropdownToggle>
                          <DropdownMenu
                            className="menu-dropdown"
                            style={{ overflow: "auto", maxHeight: "25vh" }}
                          >
                            {timeSlotsArray.map((slot, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => setSelectedTimeSlot(slot)}
                              >
                                {slot}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <br />
              <div class="d-flex justify-content-center">
                <h3>Loading...</h3>
              </div>
            </>
          )}

          <div className="btn-group btn-block">
            <Button
              className="btn btn-success mr-2 rounded"
              color="success"
              disabled={!isEditDataLoading}
              style={{ width: "100%", paddingRight: 10 }}
              onClick={editRecord}
            >
              Update
            </Button>
            <Button
              className="btn btn-danger ml-2 rounded"
              color="info"
              style={{ width: "100%", paddingLeft: 10 }}
              onClick={closeModal}
              disabled={!isEditDataLoading}

            >
              Close
            </Button>
          </div>
        </Modal>
        {/* EDIT DIALOG */}

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">Booking List</h3>
                  <h3 className="mb-0"> Total Records : {tableData.length}/{totalRecords}</h3>
                </div>
              </CardHeader>
              {!!tableData?.length ? (
                <ReactTable columns={columns} data={tableData} />
              ) : (
                <div
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    borderBottom: "1px solid grey",
                  }}
                  className="rotate-table"
                  id="record-table"
                >
                  <Table className="align-items-center table-flush rotate-table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Booking ID</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">CID</th>
                        <th scope="col">Dose</th>
                        <th scope="col">Company</th>
                        <th scope="col">EmployeeId</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Nationality</th>
                        <th scope="col">NRC or Passport Number</th>
                        <th scope="col">Gender</th>
                        <th scope="col">DOB</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Vaccination Location</th>
                        <th scope="col">Booked Date</th>
                        <th scope="col">Booked Timeslot</th>
                        <th scope="col">Invoice No</th>
                        <th scope="col">Registration Status</th>
                        <th scope="col">Vaccination Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          {isLoading ? (
                            <div> Loading... </div>
                          ) : (
                            <div>No data found</div>
                          )}
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {
                tableData.length > 0 && tableData.length < totalRecords && <div className="text-center my-4">
                  <button disabled={isLoading} className="btn btn-primary" onClick={loadMore}> Load More {isLoading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </button>
                </div>
              }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
