var companies = [
  "Activo Co.| Ltd.",
  "Advantis Myanmar",
  "AHK Myanmar",
  "AIA",
  "AIG Drivers",
  "Akaya Production",
  "ANZ Bank",
  "Apollo",
  "Asia Holidays Travels & Tours Company Limited",
  "Auntie Annes (Blue Ridge Company Limited)",
  "Awei Metta (Pun Hlaing Lodge Hotel Management Limited)",
  "Aye Myittar Textile",
  "Ball Asia Pacific (Yangon) Metal Container Limited",
  "BASF Myanmar Limited",
  "BOGART LINGERIE",
  "British American Tobacco Myanmar Limited",
  "Capital Automotive Limited",
  "Carlsberg",
  "Cathay United Bank",
  "Chindwin Investments Limited",
  "Cho Cho Co Group Of Company",
  "City Holding",
  "Coca-Cola Pinya Beverages Myanmar Co. Ltd",
  "CP MDY",
  "DantDaLun",
  "Deloitte Touche Myanmar Vigour Advisory Ltd",
  "Delta Capital",
  "DHL Supply Chain Myanmar Limited",
  "DKSH (Myanmar) Limited",
  "DKT Myanmar",
  "Ducati (SGG Motor Services Limited)",
  "Dulwich College Yangon",
  "East-West Seed Myanmar",
  "Eden Group Co.,Ltd",
  "Embassy of JAPAN",
  "Embassy of Sri Lanka",
  "EXERA Myanmar Limited",
  "FHI360 Myanmar",
  "FMI City (F.M.I Garden Development Limited)",
  "FMI Co.,Ltd",
  "FMI Flotilla Company",
  "FMIDecaux",
  "Food Panda",
  "Galaxy Software",
  "Genken Co",
  "Grand Royal",
  "Great Victory Food and Beverage Co.Ltd",
  "Head Office (Corporate) (First Myanmar Investment Public Company Limited)",
  "Head Office (Corporate) (Serge Pun & Associates (Myanmar) Limited)",
  "Head Office (Corporate) (Serge Pun & Associates (Myanmar) Limited) - GSS",
  "Head Office (Corporate) (Yoma Nominee Limited)",
  "Head Office (Corporate) (Yoma Strategic Holdings Ltd.)",
  "Head Office (Yoma Agri) (Yoma Agricultural & Logistics Holding Pte. Ltd.)",
  "Head Office (Yoma Agri) (Yoma Agriculture Company Limited)",
  "Head Office (Yoma Agri) (Zuari Yoma Agri Solutions Limited)",
  "Head Office (Yoma Motors) (Successful Goal Trading Company Limited)",
  "Jardine CM Restaurant Group Co. Ltd.",
  "JICA Myanmar Office",
  "JJ-Pun",
  "Jotun Myanmar Co. Ltd",
  "JTI",
  "KBZ Bank",
  "KFC (Summit Brands Restaurant Group Company Limited)",
  "KKTC School",
  "KOSPA/METRO (KOSPA Limited)",
  "La San Myanmar Trading Co. Ltd.",
  "Le Planteur Inya Lake Co. Ltd",
  "LingZhou (Asia Pacific) Medical Investment Management Co., Ltd.",
  "Lot 7",
  "Luxtron Co.,Ltd",
  "Makro Wholesales_ARO",
  "MBL",
  "MC-Jalux Airport Services Co.,Ltd.",
  "Meeyahta Development Limited",
  "Memories Myanmar F&B Management Co.Ltd",
  "Metro Wholesale Myanmar Ltd",
  "MFFT",
  "Millcon Thiha Gel Limited",
  "Mitsubishi Corporation",
  "MM Cars Myanmar Ltd.",
  "Mokan International Company Limited",
  "Mon Shine Co.,Ltd",
  "MPRL",
  "MPT_KSGM",
  "MUFG Bank",
  "Myanma Awba Group",
  "Myanmar Golden Rock International Co..Ltd",
  "Myanmar Institute of Directors",
  "Myanmar One Online Co., Ltd",
  "Myanmar Strategic Holding",
  "Myitta Goldshop",
  "Nature Conservancy",
  "Nestle Myanmar Limited",
  "New Holland (Convenience Prosperity Company Limited)",
  "OCK Yangon Private Limited",
  "Ooredoo Myanmar Limited",
  "Ovaltine",
  "Paribawga",
  "Peninsula yangon - YDG",
  "PHE Contractor",
  "PHE Resident",
  "Point Sunflower Group Co.,Ltd",
  "Population Services International",
  "Prudential Myanmar Life Insurance",
  "PSI/Myanmar",
  "PTTEP Myanmar Asset",
  "Pun Hlaing Estate (Hlaing River Golf And Country Club Company Limited)",
  "Pun Hlaing Hospital (Yoma Siloam Hospital Pun Hlaing Limited)",
  "Pun Hlaing Vaccine Center",
  "Pun Projects",
  "Schenker Myanmar Co. Ltd",
  "Sea Lion Co Ltd",
  "Seagram Myanmar",
  "Sealion",
  "Shine Laundry",
  "Shwe Lay Ta Gun Travels & Tours Company Limited",
  "Silver Wave Energy Pte Ltd",
  "Singapore Association of Myanmar",
  "SPA Design & Project Services Ltd",
  "SPA Marketing Ltd.",
  "Star City (Thanlyin Estate Development Limited)",
  "StarCity Residence",
  "Sumitomo",
  "Summit SPA Motors. Ltd",
  "TED Contractor",
  "Thai Samsung",
  "Toyota Tsusho Myanmar Co. Ltd",
  "Uniteam Global Business Services (MM) Ltd",
  "UOB Bank",
  "Virginia Tobacco Company Limited",
  "Volkswagen (Yoma German Motors Limited)",
  "Wave Money",
  "YarPye Company Ltd.",
  "YGA Capital",
  "YKKO (YKKO Group of Companies)",
  "Yoma Bank",
  "Yoma Central (Meeyahta Development Limited)",
  "Yoma Development Group Ltd.",
  "Yoma Financial Services / Yoma Fleet",
  "Yoma Fleet (Yoma Fleet Limited)",
  "Yoma Group",
  "Yoma Micro Power (Yoma Micro Power Myanmar Limited)",
  "Yoma Motors (Myanmar Motors Pte. Ltd.)",
  "Yoma Nominee Limited",
  "ZADA Solutions PTE Ltd",
];
export default companies;
