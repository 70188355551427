import React, { useState } from "react";
import Modal from "react-modal";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { VACCINATION_CERTIFICATE_ISSUER } from "../helpers/consts";
// core components
import CredHeader from "../components/Headers/CredHeader.js";
import Auth from "../helpers/Auth";
const fetch = require("node-fetch");
var QRCode = require("qrcode.react");
var faker = require("faker");
var moment = require("moment");
Modal.setAppElement("#root");

const Credential = (props) => {
  let connection = {};
  let userName = "";
  let userConnectionId = "";
  if (props.location.state) {
    connection = props.location.state.connection;
    userName = connection.name;
    userConnectionId = connection.connectionId;
  }

  const [modalOKIsOpen, setModalOKIsOpen] = useState(false);
  const [modalNotOKIsOpen, setModalNotOKIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [QRvisible, setQRvisible] = useState(false);
  const [QRSize, setQRSize] = useState(256);
  const [spinnerVisible, setSpinnerVisible] = useState(true);
  const [invitationURL, setInvitationURL] = useState("");
  const [isValidDocId, setIsValidDocId] = useState(false);
  const [EventType, setEventType] = useState("Vaccination");

  const [EventDropDownOpen, setEventDropDownOpen] = useState(false);
  const toggleEventDropDown = () =>
    setEventDropDownOpen((prevState) => !prevState);
  const handleEventDropDownSelect = (e) => {
    setEventType(e);
  };

  const [VaccincationDropDownOpen, setVaccincationDropDownOpen] =
    useState(false);
  const toggleVaccincationDropDown = () =>
    setVaccincationDropDownOpen((prevState) => !prevState);
  const handleVaccincationDropDownSelect = (e) => {
    setManufacturer(vaccinationManufacturerMap(e));
    setVaccineName(e);
  };

  const [GenderDropDownOpen, setGenderDropDownOpen] = useState(false);
  const toggleGenderDropDown = () =>
    setGenderDropDownOpen((prevState) => !prevState);
  const handleGenderDropDownSelect = (e) => {
    setGender(e);
  };

  const [DoseDropDownOpen, setDoseDropDownOpen] = useState(false);
  const toggleDoseDropDown = () =>
    setDoseDropDownOpen((prevState) => !prevState);
  const handleDoseDropDownSelect = (e) => {
    setDose(e);
  };

  const [DocTypeDropDownOpen, setDocTypeDropDownOpen] = useState(false);
  const toggleDocTypeDropDown = () =>
    setDocTypeDropDownOpen((prevState) => !prevState);
  const handleDocTypeDropDownSelect = (e) => {
    if (e === "Passport") {
      setIsValidDocId(true);
    }
    setDocumentType(e);
  };

  const [VirusName, setVirusName] = useState("SARS-CoV-2 (COVID-19)");
  const [TestResult, setTestResult] = useState("Negative");
  const [TestResultDropDownOpen, setTestResultDropDownOpen] = useState(false);
  const toggleTestResultDropDown = () =>
    setTestResultDropDownOpen((prevState) => !prevState);
  const handleTestResultDropDownSelect = (e) => {
    setTestResult(e);
  };

  var genderArray = ["Male", "Female", "Other"];
  var randomNumber = Math.floor(Math.random() * genderArray.length);

  const [VaccineName, setVaccineName] = useState("BIBP/Sinopharm");
  const [Manufacturer, setManufacturer] = useState(
    "Beijing Institute of Biological Products Co., Ltd. (BIBP)"
  );
  const [Dose, setDose] = useState("1/2");
  const [IssueDate, setIssueDate] = useState(moment().format("YYYY-MM-DD"));
  const [ValidateTill, setValidateTill] = useState(
    moment().add(7, "Y").format("YYYY-MM-DD")
  );
  const [Organization, setOrganization] = useState("Pun Hlaing Hospital");
  const [StaffPersonName, setStaffPersonName] = useState(
    localStorage.getItem("name")
  );
  const [FullName, setFullName] = useState(userName);
  const [connectionId, setConnectionId] = useState(userConnectionId);
  // const [DOB, setDOB] = useState(moment().subtract(30, 'Y').subtract(2, 'M').subtract(5, 'D').format("YYYY-MM-DD"))
  const [DOB, setDOB] = useState("");
  const [Nationality, setNationality] = useState("Burmese");
  const [Gender, setGender] = useState("Male");
  const [Subject, setSubject] = useState("Non-Dependent");
  const [SubjectDropDownOpen, setSubjectDropDownOpen] = useState(false);
  const toggleSubjectDropDown = () =>
    setSubjectDropDownOpen((prevState) => !prevState);
  const handleSubjectDropDownSelect = (e) => {
    setSubject(e);
  };

  const [SubjectRelation, setSubjectRelation] = useState("None");
  const [SubjectRelationDropDownOpen, setSubjectRelationDropDownOpen] =
    useState(false);
  const toggleSubjectRelationDropDown = () =>
    setSubjectRelationDropDownOpen((prevState) => !prevState);
  const handleSubjectRelationDropDownSelect = (e) => {
    setSubjectRelation(e);
  };

  const [DocumentType, setDocumentType] = useState(
    "National Registration Card (NRC)"
  );
  const [DocumentID, setDocumentID] = useState("");

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  function vaccinationManufacturerMap(name) {
    switch (name) {
      case "Pfizer-BioNTech":
        return "BioNTech Manufacturing GmbH";

      case "Spikevax (previously Moderna)":
        return "Moderna US, Inc";

      case "Vaxzevria (previously AstraZeneca)":
        return "AstraZeneca Ab";

      case "Janssen (Johnson & Johnson)":
        return "Janssen-Cilag International NV";

      case "BIBP/Sinopharm":
        return "Beijing Institute of Biological Products Co., Ltd. (BIBP)";

      case "COVISHIELD™":
        return "Serum Institute of India Pvt. Ltd";

      case "CoronaVac":
        return "Sinovac Life Sciences Co., Ltd";

      case "COVAXIN":
        return "Bharat Biotech"

      case "MODERNA COVID-19 (mRNA-1273)":
        return "Moderna US, Inc"

      default:
        return "Not Found";
    }
  }

  const signout = () => {
    Auth.signout();
    window.location.reload();
  };

  function closeModal() {
    setModalOKIsOpen(false);
    setModalNotOKIsOpen(false);
  }

  const toInputUppercase = (e) => {
    // e.target.value = ("" + e.target.value).toUpperCase();
    // console.log(DocumentType);
    if (DocumentType === "National Registration Card (NRC)") {
      if (validateNRC(e.target.value)) {
        setIsValidDocId(true);
      } else {
        setIsValidDocId(false);
      }
    } else {
      setIsValidDocId(true);
    }
  };

  const validateNRC = (nrcno) => {
    var validity = false;
    try {
      let regxp =
        /^([0-9]{1,2})\/([A-Za-z][A-Za-z]|[A-Za-z][A-Za-z][A-Za-z])([A-Za-z][A-Za-z]|[A-Za-z][A-Za-z][A-Za-z])([A-Za-z][A-Za-z]|[A-Za-z][A-Za-z][A-Za-z])\([Nn,Pp,Ee]\)[0-9]{6}$/;
      if (regxp.test(nrcno)) {
        validity = true;
      } else {
        validity = false;
      }
    } catch (e) {
      // console.log(e.message);
      validity = false;
    }

    return validity;
  };
  async function submit() {
    let isDocumentIdValid = true;
    if (DocumentType === "National Registration Card (NRC)") {
      isDocumentIdValid = validateNRC(DocumentID);
    } else {
      isDocumentIdValid = true;
    }

    if (
      isDocumentIdValid === true &&
      !isNullOrWhitespace(FullName) &&
      !isNullOrWhitespace(VirusName) &&
      !isNullOrWhitespace(connectionId) &&
      !isNullOrWhitespace(Nationality) &&
      !isNullOrWhitespace(DocumentID) &&
      DOB !== ""
    ) {
      setModalIsOpen(true);
      const covidPass = {};
      setOrganization(localStorage.getItem("admin_center"));
      covidPass = {
        "Virus Name": VirusName,
        "Vaccine Name": VaccineName,
        "Vaccine Manufacturer": Manufacturer,
        Dose: Dose,
        "Administrating Center": Organization,
        "Staff Name": StaffPersonName,
        "Full Name": FullName,
        "Birth Date": DOB,
        Nationality: Nationality,
        Gender: Gender,
        "Document Type": DocumentType,
        "Document Id": DocumentID,
        "Issue Time": moment().toISOString(),
        Country: "Myanmar",
        "Vaccination Certificate Issuer": VACCINATION_CERTIFICATE_ISSUER,
      };

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);

        let data = {
          connectionId: connectionId,
          credentialValues: JSON.stringify(covidPass),
        };

        var ISSUANCE_API_PATH = process.env.REACT_APP_API_URL + "/api/issue";
        fetch(ISSUANCE_API_PATH, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            setModalIsOpen(false);
            if (json.success === true) {
              setModalOKIsOpen(true);
            } else {
              setModalNotOKIsOpen(true);
            }
          });
      }
    } else {
      setModalIsOpen(false);
      alert("Invalid Form Attribute(s), Try Again!");
    }
  }

  return (
    <>
      <CredHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Modal
          shouldCloseOnOverlayClick={false}
          backdrop="static"
          isOpen={modalIsOpen}
          // onRequestClose={closeModal}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay"
        >
          <p>Please wait certificate is being issued!</p>
          <br />
          <div className="centered-content d-flex justify-content-center">
            {spinnerVisible && (
              <div
                id="spinner"
                className="spinner-border centered-content"
                role="status"
              >
                <span className="sr-only centered-content">Loading...</span>
              </div>
            )}
            {QRvisible && (
              <div id="qrcode">
                <QRCode size={QRSize} value={invitationURL} renderAs="svg" />
              </div>
            )}
          </div>
          <br />
          {/* <Button
            color="primary"
          // onClick={closeModal}
          >
            Close
          </Button> */}
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={false}
          backdrop="static"
          isOpen={modalOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay"
        >
          <div className="centered-content d-flex justify-content-center">
            <h4>Certificate Issuance Successful</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            <p>Certificate is successfully issued</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={require("../assets/img/theme/check.png").default}
            />
          </div>
          <br />
          <Button
            color="success"
            style={{ width: "100%" }}
            onClick={closeModal}
          >
            Continue
          </Button>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={false}
          backdrop="static"
          isOpen={modalNotOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay"
        >
          <div className="centered-content d-flex justify-content-center">
            <h4>Certificate Issuance Failed</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            <p>Unable to Issue Certificate</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={require("../assets/img/theme/cross.png").default}
            />
          </div>
          <br />
          <Button
            style={{
              width: "100%",
              backgroundColor: "#c82606",
              color: "#ffffff",
            }}
            onClick={closeModal}
          >
            Continue
          </Button>
        </Modal>

        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Certificate Issuance Form</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Certificate information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Full Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={FullName}
                            disabled={true}
                            // onChange={(e) => setFullName(e.target.value)}
                            id="input-name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-eventtype"
                          >
                            Event Type
                          </label>
                          <div id="input-eventtype">
                            <Dropdown
                              isOpen={EventDropDownOpen}
                              toggle={toggleEventDropDown}
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {EventType}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                {/* <DropdownItem onClick={() => handleEventDropDownSelect("RT-PCR Test")}>RT-PCR Test</DropdownItem> */}
                                <DropdownItem
                                  onClick={() =>
                                    handleEventDropDownSelect("Vaccination")
                                  }
                                >
                                  Vaccination
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-virusname"
                          >
                            Virus Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={VirusName}
                            onChange={(e) => setVirusName(e.target.value)}
                            id="input-virusname"
                            type="text"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-conid"
                          >
                            Connection ID
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={connectionId}
                            disabled={true}
                            id="input-conid"
                            type="text"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        {EventType === "RT-PCR Test" && (
                          <div>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-testresult"
                              >
                                Test Result
                              </label>
                              <div id="input-testresult">
                                <Dropdown
                                  isOpen={TestResultDropDownOpen}
                                  toggle={toggleTestResultDropDown}
                                >
                                  <DropdownToggle
                                    color="white"
                                    caret
                                    className="dropdown"
                                  >
                                    {TestResult}
                                  </DropdownToggle>
                                  <DropdownMenu className="menu-dropdown">
                                    <DropdownItem
                                      onClick={() =>
                                        handleTestResultDropDownSelect(
                                          "Negative"
                                        )
                                      }
                                    >
                                      Negative
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleTestResultDropDownSelect(
                                          "Positive"
                                        )
                                      }
                                    >
                                      Positive
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </FormGroup>
                          </div>
                        )}
                      </Col>
                    </Row>

                    {EventType === "Vaccination" && (
                      <div>
                        {/* ---------------------------------------- VACCINE DATA ------------------------------------------- */}
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-vaccinename"
                              >
                                Vaccination
                              </label>
                              <div id="input-eventtype">
                                <Dropdown
                                  isOpen={VaccincationDropDownOpen}
                                  toggle={toggleVaccincationDropDown}
                                >
                                  <DropdownToggle
                                    color="white"
                                    caret
                                    className="dropdown"
                                  >
                                    {VaccineName}
                                  </DropdownToggle>
                                  <DropdownMenu className="menu-dropdown">
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect(
                                          "Pfizer-BioNTech"
                                        )
                                      }
                                    >
                                      Pfizer-BioNTech
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect(
                                          "Spikevax (previously Moderna)"
                                        )
                                      }
                                    >
                                      Spikevax (previously Moderna)
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect(
                                          "Vaxzevria (previously AstraZeneca)"
                                        )
                                      }
                                    >
                                      Vaxzevria (previously AstraZeneca)
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect(
                                          "Janssen (Johnson & Johnson)"
                                        )
                                      }
                                    >
                                      Janssen (Johnson & Johnson)
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect(
                                          "BIBP/Sinopharm"
                                        )
                                      }
                                    >
                                      BIBP/Sinopharm
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect(
                                          "COVISHIELD™"
                                        )
                                      }
                                    >
                                      COVISHIELD™
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect(
                                          "CoronaVac"
                                        )
                                      }
                                    >
                                      CoronaVac
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect("COVAXIN")
                                      }
                                    >
                                      COVAXIN
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleVaccincationDropDownSelect("MODERNA COVID-19 (mRNA-1273)")
                                      }
                                    >
                                      MODERNA COVID-19 (mRNA-1273)
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-manufacturer"
                              >
                                Manufacturer
                              </label>
                              <Input
                                className="form-control-alternative"
                                disabled={true}
                                id="input-manufacturer"
                                // onChange={(e) => setManufacturer(e.target.value)}
                                // defaultValue={Manufacturer}
                                value={Manufacturer}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-dose"
                              >
                                Dose
                              </label>
                              <div id="input-dose">
                                <Dropdown
                                  isOpen={DoseDropDownOpen}
                                  toggle={toggleDoseDropDown}
                                >
                                  <DropdownToggle
                                    color="white"
                                    caret
                                    className="dropdown"
                                  >
                                    {Dose}
                                  </DropdownToggle>
                                  <DropdownMenu className="menu-dropdown">
                                    <DropdownItem
                                      onClick={() =>
                                        handleDoseDropDownSelect("1/2")
                                      }
                                    >
                                      1/2
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        handleDoseDropDownSelect("2/2")
                                      }
                                    >
                                      2/2
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* ---------------------------------------- VACCINE DATA ------------------------------------------- */}
                      </div>
                    )}

                    <Row>
                      {/* {EventType === 'Vaccination' && (
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-validto"
                            >
                              Validate Till
                          </label>
                            <Input
                              className="form-control-alternative"

                              id="input-validto"
                              onChange={(e) => setValidateTill(e.target.value)}
                              value={ValidateTill}
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      )} */}

                      {EventType === "RT-PCR Test" && (
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-validfrom"
                            >
                              Issue Date
                            </label>
                            <Input
                              className="form-control-alternative"
                              onChange={(e) => setIssueDate(e.target.value)}
                              value={IssueDate}
                              id="input-validfrom"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-staff"
                          >
                            Staff Person Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            // onChange={(e) => setStaffPersonName(e.target.value)}
                            disabled={true}
                            defaultValue={StaffPersonName}
                            id="input-staff"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-gender"
                          >
                            Gender
                          </label>
                          <div id="input-gender">
                            <Dropdown
                              isOpen={GenderDropDownOpen}
                              toggle={toggleGenderDropDown}
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {Gender}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() =>
                                    handleGenderDropDownSelect("Male")
                                  }
                                >
                                  Male
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    handleGenderDropDownSelect("Female")
                                  }
                                >
                                  Female
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    handleGenderDropDownSelect("Other")
                                  }
                                >
                                  Other
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-dob"
                          >
                            Date of Birth
                          </label>
                          <Input
                            className="form-control-alternative"
                            onChange={(e) => setDOB(e.target.value)}
                            id="input-dob"
                            value={DOB}
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nationality"
                          >
                            Nationality
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nationality"
                            onChange={(e) => setNationality(e.target.value)}
                            value={Nationality}
                            placeholder="i.e Burmese"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* {EventType === 'Vaccination' && (<div>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-subject"
                            >
                              Subject
                          </label>
                            <div
                              id="input-subject">
                              <Dropdown isOpen={SubjectDropDownOpen} toggle={toggleSubjectDropDown}>
                                <DropdownToggle color="white" caret className="dropdown">
                                  {Subject}
                                </DropdownToggle>
                                <DropdownMenu className="menu-dropdown">
                                  <DropdownItem onClick={() => handleSubjectDropDownSelect("Non-Dependent")}>Non-Dependent</DropdownItem>
                                  <DropdownItem onClick={() => handleSubjectDropDownSelect("Dependent")}>Dependent</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-subject-relation"
                            >
                              Subject Relation
                          </label>
                            <div
                              id="input-subject-relation">
                              <Dropdown isOpen={SubjectRelationDropDownOpen} toggle={toggleSubjectRelationDropDown}>
                                <DropdownToggle color="white" caret className="dropdown">
                                  {SubjectRelation}
                                </DropdownToggle>
                                <DropdownMenu className="menu-dropdown">
                                  <DropdownItem onClick={() => handleSubjectRelationDropDownSelect("None")}>None</DropdownItem>
                                  <DropdownItem onClick={() => handleSubjectRelationDropDownSelect("Offspring")}>Offspring</DropdownItem>
                                  <DropdownItem onClick={() => handleSubjectRelationDropDownSelect("Guardian")}>Guardian</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>)} */}

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-doctype"
                          >
                            Document Type
                          </label>
                          <div id="input-doctype">
                            <Dropdown
                              isOpen={DocTypeDropDownOpen}
                              toggle={toggleDocTypeDropDown}
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {DocumentType}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() =>
                                    handleDocTypeDropDownSelect("Passport")
                                  }
                                >
                                  Passport
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    handleDocTypeDropDownSelect(
                                      "National Registration Card (NRC)"
                                    )
                                  }
                                >
                                  National Registration Card (NRC)
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-docid"
                          >
                            Document ID
                          </label>
                          <Input
                            className={
                              isValidDocId
                                ? "form-control-alternative"
                                : "form-control-alternative-red"
                            }
                            onChange={(e) => setDocumentID(e.target.value)}
                            id="input-docid"
                            value={DocumentID}
                            onInput={toInputUppercase}
                            placeholder="i.e Passport or NRC Number"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>About Me</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                        Open Source."
                        type="textarea"
                      />
                    </FormGroup>
                  </div> */}
                </Form>
                <Row className="align-items-center">
                  <Col xs="8"></Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={submit}>
                      Issue
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Credential;
