import Index from "views/Index.js";
// import Profile from "views/Profile.js";
// import Icons from "views/Icons.js";
import Credential from "views/Credential.js";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import Tables from "./views/Table.js";
import BookingForm from "./views/BookingForm";
import CanScope from "./helpers/ScopePermissions";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  {
    path: "/tables",
    name: "Bookings",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
  },
  CanScope("create_booking") && {
    path: "/booking",
    name: "Add Booking",
    icon: "ni ni-folder-17 text-red",
    component: BookingForm,
    layout: "/admin",
  },
  // {
  //   path: "/issue",
  //   name: "Issue Credentials",
  //   icon: "ni ni-credit-card text-green",
  //   component: Credential,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
