import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import React, { useState, useRef } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import Auth from '../helpers/Auth';
const fetch = require('node-fetch');
var moment = require('moment');

// core components

const Upload = (props) => {
  const mainContent = React.useRef(null);
  const [connectionButtonLabel, setConnectionButtonLabel] = useState('Click to generate connection QR code')
  const [credButtonLabel, setCredButtonLabel] = useState('Issue digital credential')

  const [fullname, setFullName] = useState('John Doe')
  const [age, setAge] = useState('33')
  const [country, setCountry] = useState('Sweden')
  const [qrURL, setQRURL] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [connectionId, setConnectionId] = useState('');


  const [uploadButtonText, setUploadButtonText] = useState('Upload');
  const [date, setDate] = useState('');
  const [serial, setSerial] = useState('');
  const [center, setCenter] = useState('1');
  const [CenterName, setCenterName] = useState('1 - Pun Hlaing Vaccination Center (Dulwich College - Hlaing Tharyar)')
  const [CenterDropDownOpen, setCenterDropDownOpen] = useState(false);
  let selectedCenter = '1';
  const toggleCenterDropDown = () => setCenterDropDownOpen(prevState => !prevState);
  const handleCenterDropDownSelect = (e) => {
    setCenterName(e);
    selectedCenter = e.split(' - ')[0];
    setCenter(e.split(' - ')[0]);
  }

  const signout = () => {
    Auth.signout();
    window.location.reload();
  };


  const upload = async () => {

  }

  const [file, setFile] = useState("");
  let inputFile = useRef(null);

  const handleFileUpload = async (e) => {
    try {
      var fileInput = document.getElementById('file');
      var filePath = fileInput.value;
      var allowedExtensions = /(\.csv)$/i;
      if (!allowedExtensions.exec(filePath)) {
        alert('Please upload file having extensions .csv only.');
        fileInput.value = '';
        return false;
      }



      setIsLoading(true);
      setUploadButtonText('Uploading, Please wait...');
      const { files } = e.target;
      if (files && files.length && files[0].size) {

        const filename = files[0].name;

        var parts = filename.split(".");
        const fileType = parts[parts.length - 1];
        // console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.

        setFile(files[0]);
        const formData = new FormData()
        formData.append('center', center)
        formData.append('serial', serial)
        // formData.append('date', moment(date).format('YYYYMMDD'))
        formData.append('csvfile', files[0])

        var API_PATH = process.env.REACT_APP_API_URL + '/api/upload';
        const res = await axios({
          url: API_PATH,
          data: formData,
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') }
        });

        if (res.data.status == 200) {

          setIsLoading(false);
          setUploadButtonText('Upload');
          setFile('');
          // console.log("res data: ", res.data)
          if (!res.data.included) {
            // alert(`CSV headers not matched with selected schema.`)
            return toast.error(`CSV headers not matched with the selected schema.`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (res.data.errorList) {
            res.data.errorList?.map((data) => {


              return toast.error(`${data}`, {
                position: "top-left",
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            })

          }
          if (res.data?.successfullyInserted) {
            // alert(`CSV headers not matched with selected schema.`)
            toast.success(`Booking Added Successfully.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (res.data?.inValidRow) {
            res.data.inValidRow?.map((data, i) => {

              toast.error(`${data} in Line no. ${res.data.inValidRowNumber[i] + 1}.`, {
                position: "top-left",
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

              });

            })

          }

          //  alert(res.data.message);
        }
        else {
          setIsLoading(false);
          setUploadButtonText('Upload');
          setFile('');
          alert(res.data.message);

        }

        // await fetch(API_PATH, {
        //   method: 'POST',
        //   body: formData,
        //   headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') }
        // }).then(res => res.json())
        //   .then(json => {
        //     console.log(json);
        //     setIsLoading(false);
        //     setFile('');
        //     setUploadButtonText('Upload');
        //     if (json.status == 200)
        //       alert('Booking Added Successfully!');
        //   });
      } else {
        toast.error(`csv file is empty.`, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
        setUploadButtonText('Upload');
        setFile('');

      }
    } catch (error) {
      setIsLoading(false);
      setUploadButtonText('Upload');
      setFile('');
    }
  };

  const onButtonClick = () => {
    inputFile.current.value = "";
    inputFile.current.click();
  };




  return (
    <>
      <ToastContainer />

      <div className="main-content" ref={mainContent} style={{ margin: 30 }}>
        <h1><b>ZADA Registration Upload</b></h1>
        <div style={{ marginTop: 40 }}>
          <p><b>How to use:</b> Select a HTY center and Date and upload the CSV file.</p>
          <br />
          <h2>Step 1: Select Center</h2>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-vaccinename"
            >
              Center List
            </label>
            <div
              id="input-eventtype">
              <Dropdown isOpen={CenterDropDownOpen} toggle={toggleCenterDropDown}>
                <DropdownToggle color="white" caret className="dropdown">
                  {CenterName}
                </DropdownToggle>
                <DropdownMenu className="menu-dropdown">
                  <DropdownItem onClick={() => handleCenterDropDownSelect("1 - Pun Hlaing Vaccination Center (Dulwich College - Hlaing Tharyar)")}>1 - Pun Hlaing Vaccination Center (Dulwich College - Hlaing Tharyar)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("2 - Pun Hlaing Vaccination Center (Star City)")}>2 - Pun Hlaing Vaccination Center (Star City)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("3 - Pun Hlaing Hospital (Mandalay)")}>3 - Pun Hlaing Hospital (Mandalay)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("4 - Pun Hlaing Hospital (Taunggyi)")}>4 - Pun Hlaing Hospital (Taunggyi)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("5 - Pun Hlaing Hospitals (Yangon - Hlaing Tharyar)")}>5 - Pun Hlaing Hospitals (Yangon - Hlaing Tharyar)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("6 - Pun Hlaing Clinic (TWC)")}>6 - Pun Hlaing Clinic (TWC)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("7 - Pun Hlaing Clinic (NDC)")}>7 - Pun Hlaing Clinic (NDC)</DropdownItem>
                  {/* <DropdownItem onClick={() => handleCenterDropDownSelect("8 - Pun Hlaing Hospital (MDY)")}>8 - Pun Hlaing Hospital (MDY)</DropdownItem> */}
                  {/* <DropdownItem onClick={() => handleCenterDropDownSelect("9 - Pun Hlaing Hospital (TGY)")}>9 - Pun Hlaing Hospital (TGY)</DropdownItem> */}
                  <DropdownItem onClick={() => handleCenterDropDownSelect("10 - Pun Hlaing - Star City Clinic (SCC)")}>10 - Pun Hlaing - Star City Clinic (SCC)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("11 - Pun Hlaing Family Clinics - Sanchaung (SCG)")}>11 - Pun Hlaing Family Clinics - Sanchaung (SCG)</DropdownItem>
                  <DropdownItem onClick={() => handleCenterDropDownSelect("12 - Pun Hlaing Family Clinics - Thingangyun")}>12 - Pun Hlaing Family Clinics - Thingangyun</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </FormGroup>
        </div>

        <hr />
        {/* <div style={{ marginTop: 20 }}>
        <h2>Step 2: Select Date</h2>
        <Input
          className="form-control-alternative"
          id="input"
          placeholder="Date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          type="date"
        />
      </div>
      <hr /> */}
        <div style={{ marginTop: 20 }}>
          <h2>Step 2: Input Starting Serial</h2>
          <Input
            className="form-control-alternative"
            id="input"
            placeholder="0001"
            value={serial}
            onChange={(e) => setSerial(e.target.value)}
            type="number"
          />
        </div>
        <hr />
        <div style={{ marginTop: 20 }}>
          <h2>Step 3: Upload CSV</h2>
          <div>
            <input
              id="file"
              style={{ display: "none" }}
              // accept=".zip,.rar"
              ref={inputFile}
              onChange={handleFileUpload}
              type="file"
              accept=".csv"
            />
            {/* <div className="button" onClick={onButtonClick}>
            Upload
      </div> */}
          </div>
          <Button
            className="mr-4"
            color="default"
            disabled={serial && !isLoading ? false : true}
            onClick={onButtonClick}
            size="l"
          >
            {uploadButtonText}
          </Button>
        </div>
        <div style={{ marginTop: 20 }}>
          <hr />
          <h2>Sign out user</h2>
          <Button
            className="mr-4"
            color="default"
            onClick={signout}
            size="l"
          >
            Sign out
          </Button>
        </div>


      </div >
    </>
  );
};

export default Upload;
