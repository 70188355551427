import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import AdminLayout from "./layouts/Admin.js";
import UploadLayout from "./layouts/Upload.js";
import AuthLayout from "layouts/Auth.js";
import Auth from "./helpers/Auth";

function App() {
  let isAuthenticated = Auth.getAuth();
  if (isAuthenticated === undefined) {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route
              path="/auth/*"
              render={(props) => <AuthLayout {...props} />}
            />
            <Route path="*">
              <Redirect to="/auth/login" />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  } else {
    if (isAuthenticated === "uploader") {
      return (
        <div className="App">
          <Router>
            <Switch>
              <Route
                path="/upload"
                render={(props) => <UploadLayout {...props} />}
              />
              <Route path="*">
                <Redirect to="/upload" />
              </Route>
            </Switch>
          </Router>
        </div>
      );
    } else {
      return (
        <div className="App">
          <Router>
            <Switch>
              <Route
                path="/admin/*"
                render={(props) => <AdminLayout {...props} />}
              />
              <Route path="*">
                {/* <Redirect to="/admin/index" /> */}
                <Redirect to="/admin/tables" />
              </Route>
            </Switch>
          </Router>
        </div>
      );
    }
  }
}

export default App;
