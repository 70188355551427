import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

const CredHeader = (props) => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "0px",
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/creds-cover.jpg").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col>
              <h1 className="display-2 text-white">
                {props.heading ? props.heading : "Issue ZADA COVIDPass"}
              </h1>
            </Col>
            {/* <Col lg="7" md="10">
              <h1 className="display-2 text-white">Issue ZADA COVIDPass</h1>
              <p className="text-white mt-0 mb-5">
                You can issue a digital COVIDTravel pass to your consumers. This digital certificate will be self-sovereign identity based verifiable credential (VC) and can be digitly verified while maintaing user privacy.
              </p>
              <Button
                color="info"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Find out more
              </Button>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CredHeader;
