import React, { useState } from "react";
import Auth from "../../helpers/Auth";
// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
const jwt = require("jsonwebtoken");

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorVisibilityState, setErrorVisibilityState] = useState(false);
  const Authenticate = () => {
    setErrorVisibilityState(false);
    try {
      var details = {
        username: String(username).toLowerCase(),
        password: String(password),
      };

      var LOGIN_API_PATH = process.env.REACT_APP_API_URL + "/api/login";

      fetch(LOGIN_API_PATH, {
        method: "POST",
        body: JSON.stringify(details),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json["success"]) {
            //TODO: verify token for more security
            let decodedToken = jwt.decode(json.token);
            let tokenRole = decodedToken["role"];
            if (tokenRole === "booker") {
              localStorage.setItem("name", decodedToken["name"]);
              localStorage.setItem("image", decodedToken["image"]);
              localStorage.setItem(
                "admin_center",
                decodedToken["admin_center"]
              );
              localStorage.setItem("firstLoad", "true");
              localStorage.setItem("scope", decodedToken["scope"]);
              Auth.authenticate(json.token, json.exp);
              window.location.reload();
            } else if (tokenRole === "uploader") {
              localStorage.setItem("name", decodedToken["name"]);
              Auth.authenticate(json.token, json.exp);
              window.location.reload();
            } else {
              console.log("Unknown user type!");
              setErrorVisibilityState(true);
            }
          } else {
            //login unsuccessful error
            setErrorVisibilityState(true);
          }
        });
    } catch (error) {
      //print error
      setErrorVisibilityState(true);
      console.log(error);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          {/* <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader> */}
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              {/* <small>Or sign in with credentials</small> */}
              <small>Sign in with credentials</small>
            </div>
            {errorVisibilityState && (
              <div className="text-center text-muted mb-4">
                {/* <small>Or sign in with credentials</small> */}
                <small style={{ color: "red" }}>
                  Invalid username or password, Please try again!
                </small>
              </div>
            )}
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-user-run" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={Authenticate}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
