import React, { useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Table,
} from "reactstrap";
import Select from "react-select";
var moment = require("moment");
const Header = (props) => {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [bookingId, setSetbookingId] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [forDate, setForDate] = useState(moment().format("YYYY-MM-DD"));
  const [forCenter, setForCenter] = useState("01");
  const centerArray = [
    { label: "Hlaing Thar Yar", value: "01" },
    { label: "Star City", value: "02" },
    { label: "Mandalay", value: "03" },
    { label: "Taunggyi", value: "04" },
    { label: "Yangon", value: "05" },
  ]
  return (
    <>
      <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto">
                  <label
                    htmlFor="search"
                    style={{ color: "white", marginLeft: 10, marginTop: 5 }}
                  >
                    <b>Search by Phone #:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginLeft: 15 }}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search by phone #"
                      type="text"
                      style={{ width: "auto" }}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phone}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          props.phoneSearch(e.target.value);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

                <div className="col-auto" style={{ marginLeft: "13px" }}>
                  <Button
                    color="default"
                    onClick={props.phoneSearch.bind(null, phone)}
                    size="l"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Row>
            <hr />

            <Row>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto">
                  <label
                    htmlFor="search"
                    style={{ color: "white", marginLeft: 10, marginTop: 5 }}
                  >
                    <b>Search by Booking ID:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginLeft: 15 }}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search by booking ID"
                      type="text"
                      style={{ width: "auto" }}
                      onChange={(e) => setSetbookingId(e.target.value)}
                      value={bookingId}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          props.deepSearch(e.target.value);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

                <div className="col-auto" style={{ marginLeft: "13px" }}>
                  <Button
                    color="default"
                    onClick={props.deepSearch.bind(null, bookingId)}
                    size="l"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Row>
            <hr />

            <Row>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto">
                  <label
                    htmlFor="search"
                    style={{ color: "white", marginLeft: 10, marginTop: 5 }}
                  >
                    <b>Search by Invoice No:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginLeft: 15 }}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search by Invoice No"
                      type="text"
                      style={{ width: "auto" }}
                      onChange={(e) => setInvoiceNo(e.target.value)}
                      value={invoiceNo}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          props.invoiceNoSearch(e.target.value);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

                <div className="col-auto" style={{ marginLeft: "13px" }}>
                  <Button
                    color="default"
                    onClick={props.invoiceNoSearch.bind(null, invoiceNo)}
                    size="l"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Row>
            <hr />

            <Row>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="fromDate"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white", marginRight: 20 }}
                  >
                    <b>From:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <Input
                    placeholder="From"
                    style={{ width: "auto" }}
                    id="fromDate"
                    type="date"
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                  // onKeyUp={(e) => {
                  //   if (e.key === "Enter") {
                  //     props.filterSearch(e.target.value, toDate);
                  //   }
                  // }}
                  />
                </div>
              </div>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="toDate"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white", marginRight: 40 }}
                  >
                    <b>To: </b>
                  </label>
                </div>

                <div className="col-auto" style={{ marginTop: 10 }}>
                  <Input
                    placeholder="To"
                    style={{ width: "auto" }}
                    id="toDate"
                    type="date"
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        props.filterSearch(fromDate, e.target.value);
                      }
                    }}
                  />
                </div>

                <div
                  className="col-auto"
                  style={{ marginLeft: 15, marginTop: 10 }}
                >
                  <Button
                    color="default"
                    onClick={props.filterSearch.bind(null, fromDate, toDate)}
                    size="l"
                  >
                    Search by Date Range
                  </Button>
                </div>
              </div>

              {/* <div className="form-inline">
                <div className="form-group mb-2">
                  
                  <Input placeholder="From" id="fromDate" type="date" onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate} />
                </div>
                <div className="form-group mx-sm-3 mb-2">
                  <label htmlFor="toDate" className="col-sm-2 col-form-label" style={{ color: 'white', marginRight: 10 }}><b>To:</b></label>
                  <Input placeholder="To" id="toDate" type="date" onChange={(e) => setToDate(e.target.value)}
                    value={toDate} />
                </div>

                <div className="form-group mx-sm-3 mb-2">
                  <Button
                    color="default"
                    onClick={props.filterSearch.bind(null, fromDate, toDate)}
                    size="l">
                    Search by Date
                                            </Button>
                </div>
              </div> */}
            </Row>

            <Row>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="forDate"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white", marginRight: 22 }}
                  >
                    <b>Date:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <Input
                    placeholder="Date"
                    style={{ width: "auto" }}
                    id="forDate"
                    type="date"
                    onChange={(e) => setForDate(e.target.value)}
                    value={forDate}
                  // onKeyUp={(e) => {
                  //   if (e.key === "Enter") {
                  //     props.filterSearch(e.target.value, toDate);
                  //   }
                  // }}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="forCenter"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white" }}
                  >
                    <b>Center:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginTop: 10, width: 235 }}>
                  <Select
                    id="forCenter"
                    defaultValue={{ label: "Hlaing Thar Yar", value: "01" }}
                    options={centerArray}
                    onChange={(e) => {
                      setForCenter(e.value);
                    }}
                  />
                </div>
              </div>

              <div className="align-items-center">
                <div
                  className="col-auto"
                  style={{ marginLeft: 15, marginTop: 10 }}
                >
                  <Button
                    color="default"
                    onClick={props.filterSearchByDateAndCenter.bind(null, forDate, forCenter)}
                    size="l"
                  >
                    Search by Date & Center
                  </Button>
                </div>
              </div>

            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
